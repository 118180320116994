import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cancelSchedule } from '../../redux/actions/schedule';
import moment from 'moment';

import { findUsers } from '../../redux/actions/user';

class ScheduleUserModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      q: null
    };

    this.selectUser = this.selectUser.bind(this);
    this.onSearchUser = this.onSearchUser.bind(this);
  }

  onSearchUser(ref) {
    this.props.findUsers(this.state.q);
  }

  
  selectUser(user) {
    this.props.selectUser(user);
    window.$('#ScheduleUserModal').modal('toggle')
  }

  render() {
    const { findResults } = this.props;
    return (
    <div id="ScheduleUserModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">Khách hàng</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

            <div className="row">
              <div className="col-md-12">
                <div className="input-group">
                  <input type="text" onChange={(ref) => this.setState({ q: ref.target.value })} className="form-control" placeholder="Tìm kiếm khách hàng theo email hoặc sdt" />
                  <button className="btn btn-primary ml-3" onClick={this.onSearchUser}>Tìm kiếm</button>
                </div>
              </div>
            </div>
            <table className="table table-bordered mt-3">
              <thead>
                <tr>
                  <th>Tên</th>
                  <th>Email</th>
                  <th>SDT</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {findResults && findResults.map(user => 
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>
                      <button className="btn btn-outline-primary" onClick={() => this.selectUser(user)}>Chọn</button>
                    </td>
                  </tr>
                )
                }
                
              </tbody>
            </table>
            {(!findResults || findResults.length === 0) &&
              <p>Không có kết quả nào</p>
            }
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-danger mr-auto" onClick={this.onCancel}>Hủy lịch</button>
            <button type="button" className="btn btn-secondary">Chỉnh sửa</button>
            <button type="button" className="btn btn-primary" data-dismiss="modal">Đã xong</button>
          </div> */}
        </div>
      </div>
    </div>
    )
  }
}


const mapStateToProps = state => ({
  findResults: state.user.findResults
});
const mapDispatchToProps = {
  cancelSchedule,
  findUsers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleUserModal);