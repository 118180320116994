import { GET_SHOP, FAVORITE_SHOP, GET_SHOP_DETAIL, GET_DAY_OF_WEEK, UPDATE_SHOP, CREATE_SHOP } from '../actions/shop';

const initialState = {
  isFetching: false,
  isUpdating: false,
  isCreating: false,
  isFavoriting: false,
  updateResult: null,
  createResult: null,
  shops: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOP.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case GET_SHOP.SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.result
      };

    case GET_SHOP.FAIL: {
      return {
        ...state,
        isFetching: false
      };
    }

    case GET_SHOP_DETAIL.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case GET_SHOP_DETAIL.SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentShop: action.result
      };

    case GET_SHOP_DETAIL.FAIL: {
      return {
        ...state,
        isFetching: false,
        currentShop: null
      };
    }

    case UPDATE_SHOP.REQUEST:
      return {
        ...state,
        isUpdating: true
      };

    case UPDATE_SHOP.SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateResult: action.result
      };

    case UPDATE_SHOP.FAIL: {
      return {
        ...state,
        isUpdating: false,
        createResult: action.result
      };
    }

    case CREATE_SHOP.REQUEST:
      return {
        ...state,
        isCreating: true
      };

    case CREATE_SHOP.SUCCESS:
      return {
        ...state,
        isCreating: false,
        createResult: action.result
      };

    case CREATE_SHOP.FAIL: {
      return {
        ...state,
        isCreating: false,
        createResult: action.result
      };
    }

    case GET_DAY_OF_WEEK.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case GET_DAY_OF_WEEK.SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.result
      };

    case GET_DAY_OF_WEEK.FAIL: {
      return {
        ...state,
        isFetching: false,
        dayOfWeeks: []
      };
    }

    case FAVORITE_SHOP.REQUEST:
      return {
        ...state,
        isFavoriting: true,
        shopId: action.shopId
      };

    case FAVORITE_SHOP.SUCCESS:
      let { shops = [], shopId } = state;
      let shop = shops.find(x => x.id === shopId);
      if (typeof shop === 'object') {
        shop.isFavorited = true;
      }

      return {
        isFetching: false,
        isFavoriting: false,
        shops: Array.from(shops)
      };

    case FAVORITE_SHOP.FAIL: {
      return {
        ...state,
        isFavoriting: false
      };
    }

    default:
      return state;
  }
};
