import { FIND_USERS } from '../actions/user';

const initialState = {
  isFetching: false,
  findResults: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FIND_USERS.REQUEST:
      return {
        ...state,
        findResults: [],
        isFetching: true
      };

    case FIND_USERS.SUCCESS:
      
      return {
        ...state,
        isFetching: false,
        findResults: action.result.users
      };

    case FIND_USERS.FAIL: {
      return {
        ...state,
        isFetching: false,
      };
    }

    default:
      return state;
  }
};
