import {
  GET_SCHEDULE_NOTIFICATIONS,
  ADD_SCHEDULE_NOTIFICATION,
  DELETE_SCHEDULE_NOTIFICATION
} from '../actions/schedule_notification';

const initialState = {
  isFetching: false,
  photo: null,
  notifications: [],
  create: {
    isFetching: false,
    success: null,
    result: null
  },
  delete: {
    isFetching: false,
    success: null,
    result: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULE_NOTIFICATIONS.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case GET_SCHEDULE_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.result
      };

    case GET_SCHEDULE_NOTIFICATIONS.FAIL: {
      return {
        ...state,
        isFetching: false
      };
    }

    case ADD_SCHEDULE_NOTIFICATION.REQUEST:
      return {
        ...state,
        create: {
          isFetching: true,
          success: null,
          result: null
        }
      };

    case ADD_SCHEDULE_NOTIFICATION.SUCCESS:
      return {
        ...state,
        create: {
          isFetching: false,
          success: true,
          result: action.data
        },
        ...action.result
      };

    case ADD_SCHEDULE_NOTIFICATION.FAIL: {
      return {
        ...state,
        create: {
          isFetching: false,
          success: false,
          result: null
        }
      };
    }

    case DELETE_SCHEDULE_NOTIFICATION.REQUEST:
      return {
        ...state,
        delete: {
          isFetching: true,
          success: null,
          result: null
        }
      };

    case DELETE_SCHEDULE_NOTIFICATION.SUCCESS:
      return {
        ...state,
        delete: {
          isFetching: false,
          success: true,
          result: action.data
        },
        ...action.result
      };

    case DELETE_SCHEDULE_NOTIFICATION.FAIL: {
      return {
        ...state,
        delete: {
          isFetching: false,
          success: false,
          result: null
        }
      };
    }

    default:
      return state;
  }
};
