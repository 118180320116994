import { takeEvery, put, select } from 'redux-saga/effects';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosDelete } from './utils';
import queryString from 'query-string';
import { UPLOAD_PHOTO, DELETE_PHOTO } from '../actions/photo';
import { GALLERY_REFRESH } from '../actions/gallery';

function* uploadPhoto(obj) {
  const galleryId = obj.images[0].galleryId;
  for(var i = 0; i < obj.images.length; i++) {
    const item = obj.images[i];

    var formData = new FormData();
    formData.append("image", item.image);
    formData.append("name", item.name);
    formData.append("galleryId", item.galleryId);
    const result = yield axiosPost(API.PHOTO.UPLOAD_PHOTO, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    if (result.success) {
      yield put({
        type: UPLOAD_PHOTO.SUCCESS,
        result: result.data
      });
    } else {
      yield put({
        type: UPLOAD_PHOTO.FAIL,
        result
      });
    }
  };
  yield put({
    type: GALLERY_REFRESH.REQUEST,
    gallery: galleryId
  });
}

function* deletePhoto(obj) {
  const result = yield axiosDelete(API.PHOTO.DELETE_PHOTO.replace('{id}', obj.id));
  if (result.success) {
    yield put({
      type: DELETE_PHOTO.SUCCESS,
      result: result.data
    });

    const getGallery = state => state.gallery.gallery
    const gallery = yield select(getGallery);
    if(gallery) {
      yield put({
        type: GALLERY_REFRESH.REQUEST,
        gallery: gallery.id
      });
    }
  } else {
    yield put({
      type: DELETE_PHOTO.FAIL,
      result
    });
  }
}

export function* watchPhoto() {
  yield takeEvery(UPLOAD_PHOTO.REQUEST, uploadPhoto);
  yield takeEvery(DELETE_PHOTO.REQUEST, deletePhoto);
}
