import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import { Link, Redirect } from 'react-router-dom';
import CouponModal from '../../components/Coupon/CouponModal';

import { getCoupons, deleteCoupon } from '../../redux/actions/coupon';
import { COUPON_TYPE } from '../../configs/Constants';

export class Coupon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentCoupon: {}
    };

    this.onEdit = this.onEdit.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.props.getCoupons();
  }

  onEdit(coupon) {
    this.setState({
      currentCoupon: coupon
    }, () => {
      window.$('#CouponModal').modal('toggle');
    })
  }

  onDelete(id) {
    if(window.confirm("Bạn có chắc chắn muốn xóa mã giảm giá này")) {
      this.props.deleteCoupon(id);
    }
  }

  onAdd() {
    this.setState({
      currentCoupon: {
        id: 'new',
        name: '',
        code: '',
        type: 'public',
        description: '',
        discount_value: 10,
        discount_type: 'percent',
        start_at: moment().format('YYYY-MM-DD'),
        end_at: moment().format('YYYY-MM-DD'),
      }
    }, () => {
      window.$('#CouponModal').modal('toggle');
    })
  }

  render() {
    const { coupons, user } = this.props;
    if(!user || !user.isAdmin) {
      return <Redirect to="/" />
    }
    return <div className="container" style={{ marginTop: '80px' }}>
      <CouponModal coupon={this.state.currentCoupon} />
      <button className="btn btn-link float-right" onClick={this.onAdd}>+ Tạo mã giảm giá</button>
      <table className="table table-bordered mt-3">
        <thead>
          <tr>
            <th>Tên</th>
            <th>Code</th>
            <th>Loại</th>
            <th>Mô tả</th>
            <th>Trị giá</th>
            <th>Bắt đầu</th>
            <th>Kết thúc</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {coupons && coupons.map(coupon => 
            <tr key={coupon.id}>
              <td>
                <Link to={'/coupon/' + coupon.id + '/users'}>
                {coupon.name}
                </Link>
              </td>
              <td>
              <Link to={'/coupon/' + coupon.id + '/users'}>
                {coupon.code}
                </Link>
              </td>
              <td>
                <p>{COUPON_TYPE[coupon.type]}</p>
                
              </td>
              <td>{coupon.description}</td>
              <td>{coupon.discount_type === 'percent' ? coupon.discount_value + '%' : numeral(coupon.discount_value).format('0,0') + ' VND'}</td>
              <td>{moment(coupon.start_at).format('DD/MM/YYYY')}</td>
              <td>{moment(coupon.end_at).format('DD/MM/YYYY')}</td>
              <td className="fit">
                {coupon.type === 'limit' &&
                [<Link className="btn btn-outline-success mb-3" to={'/coupon/' + coupon.id + '/users'}>Danh Sách Người Dùng</Link>, <br/>]}
                
                <button className="btn btn-outline-primary" onClick={() => this.onEdit(coupon)}>Sửa</button>
                <button className="btn btn-outline-danger ml-3" onClick={() => this.onDelete(coupon.id)}>Xóa</button>
              </td>
            </tr>
          )
          }
          
        </tbody>
      </table>
    </div>
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  coupons: state.coupon.coupons
});
const mapDispatchToProps = {
  getCoupons, deleteCoupon
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupon);