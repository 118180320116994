import { createRequestTypes } from './utils';

export const GET_SCHEDULE_NOTIFICATIONS = createRequestTypes('GET_SCHEDULE_NOTIFICATIONS');
export const ADD_SCHEDULE_NOTIFICATION = createRequestTypes('ADD_SCHEDULE_NOTIFICATION');
export const DELETE_SCHEDULE_NOTIFICATION = createRequestTypes('DELETE_SCHEDULE_NOTIFICATION');

export const getScheduleNotifications = (page = 1) => ({
  type: GET_SCHEDULE_NOTIFICATIONS.REQUEST,
  page
});

export const addScheduleNotification = (title, body, push_at) => ({
  type: ADD_SCHEDULE_NOTIFICATION.REQUEST,
  title,
  body,
  push_at
})

export const deleteScheduleNotification = (id) => ({
  type: DELETE_SCHEDULE_NOTIFICATION.REQUEST,
  id
});