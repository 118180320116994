import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
// import GoogleMapReact from 'google-map-react';

import { Link, Redirect } from 'react-router-dom';
import CouponModal from '../../components/Coupon/CouponModal';

import { getProvinces } from '../../redux/actions/province';
import { getShopDetail } from '../../redux/actions/shop';
import { COUPON_TYPE } from '../../configs/Constants';
import ShopForm from '../../components/Shop/ShopForm';
import { getDayOfWeek } from '../../redux/actions/shop'
import Swal from 'sweetalert2'

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export class Coupon extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isUpdating && !this.props.isUpdating) {
      if(this.props.updateResult && this.props.updateResult.success) {
        Swal.fire("", "Cập nhật cửa hàng thành công!", 'success');
      }
      else {
        Swal.fire("", "Có lỗi xảy ra, vui lòng thử lại", 'error');
      }
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getProvinces({ advanced: 1 });
    this.props.getDayOfWeek()

    if(id) {
      this.props.getShopDetail(id);
    }
  }

  render() {
    const { shop, user } = this.props;
    if(!user || !user.isAdmin) {
      return <Redirect to="/" />
    }
    return <div className="container" style={{ marginTop: '80px' }}>
      <ShopForm shop={shop} />
    </div>
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  shop: state.shop.currentShop,
  isUpdating: state.shop.isUpdating,
  updateResult: state.shop.updateResult
});
const mapDispatchToProps = {
  getShopDetail, getProvinces, getDayOfWeek
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupon);