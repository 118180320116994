import { takeEvery, put } from 'redux-saga/effects';
import { GET_CONVERSATIONS, SEND_MESSAGE, SEND_IMAGE, RECEIVED_MESSAGE } from '../actions/conversation';
import API from '../../configs/API';
import { axiosGet, axiosPost } from './utils';
import queryString from 'query-string';

function* getConversations() {
  const result = yield axiosGet(API.CONVERSATION.GET);
  if (result.success) {
    yield put({
      type: GET_CONVERSATIONS.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_CONVERSATIONS.FAIL,
      result
    });
  }
}

function* sendMessage(obj) {
  const result = yield axiosPost(API.CONVERSATION.SEND_MESSAGE, {
    conversationId: obj.conversationId,
    content: obj.content,
    tempId: Math.random()
  });
  if (result.success) {
    yield put({
      type: SEND_MESSAGE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: SEND_MESSAGE.FAIL,
      result
    });
  }
}

function* sendImageMessage(obj) {
  
  var formData = new FormData();
  formData.append("image", obj.image);
  formData.append("tempId", obj.conversationId);
  formData.append("conversationId", obj.conversationId);
  formData.append("content", "[image]");

  const result = yield axiosPost(API.CONVERSATION.SEND_IMAGE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  if (result.success) {
    yield put({
      type: SEND_MESSAGE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: SEND_MESSAGE.FAIL,
      result
    });
  }
}

function* receivedMessage(obj) {
  const result = yield axiosPost(API.CONVERSATION.RECEIVED, {
    messageId: obj.messageId
  });

  if (result.success) {
    yield put({
      type: RECEIVED_MESSAGE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: RECEIVED_MESSAGE.FAIL,
      result
    });
  }
}

export function* watchConversation() {
  yield takeEvery(GET_CONVERSATIONS.REQUEST, getConversations);
  yield takeEvery(SEND_MESSAGE.REQUEST, sendMessage);
  yield takeEvery(RECEIVED_MESSAGE.REQUEST, receivedMessage);
  yield takeEvery(SEND_IMAGE.REQUEST, sendImageMessage);
}
