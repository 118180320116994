import { LOGIN, LOGIN_FACEBOOK, LOGIN_GOOGLE, LOGOUT } from '../actions/login';
import axios from 'axios';

const initialState = {
  isLogged: false,
  isRequestLoging: false,
  success: null,
  token: null,
  user: null,
  userId: 0,
  errorMessage: null
};

export default (state = initialState, action) => {
  if (action.type.endsWith('_FAIL')) {
    if (
      action.result.errorMessage &&
      action.result.errorMessage.status === 401 &&
      state.userId !== 0
    ) {
      return initialState;
    }
  }
  switch (action.type) {
    case LOGOUT:
      axios.defaults.headers.common.Authorization = null;
      
      return initialState;

    case LOGIN_FACEBOOK:
      return { ...state, isRequestLoging: true, isLogged: false };

    case LOGIN_GOOGLE:
      return { ...state, isRequestLoging: true, isLogged: false };

    case LOGIN.REQUEST:
      return { ...state, isRequestLoging: true, isLogged: false, success: null };

    case LOGIN.SUCCESS:
      axios.defaults.headers.common.Authorization = 'Bearer ' + action.result.token;
      return {
        ...state,
        isLogged: true,
        isRequestLoging: false,
        success: true,
        token: action.result.token,
        user: action.result.user,
        userId: action.result.user.id 
      };

    case LOGIN.FAIL:
      
      return {
        ...state,
        isLogged: false,
        isRequestLoging: false,
        success: false,
        errorMessage: action.result.error
      };

    default:
      return state;
  }
};
