import { createRequestTypes } from './utils';

export const LOGIN_FACEBOOK = 'LOGIN_FACEBOOK';
export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';
export const LOGIN = createRequestTypes('LOGIN_REGULAR');
export const LOGOUT = 'LOGOUT';

export const logout = () => ({ type: 'LOGOUT' });
export const login = (email, password) => ({
  type: LOGIN.REQUEST,
  email,
  password
});
export const loginFacebook = accessToken => ({
  type: LOGIN_FACEBOOK,
  accessToken
});
export const loginGoogle = accessToken => ({ type: LOGIN_GOOGLE, accessToken });
