import { createRequestTypes } from './utils';

export const DELETE_GALLERY = createRequestTypes('DELETE_GALLERY');
export const UPDATE_GALLERY = createRequestTypes('UPDATE_GALLERY');
export const CREATE_GALLERY = createRequestTypes('CREATE_GALLERY');
export const GET_GALLERY = createRequestTypes('GET_GALLERY');
export const GALLERY_DETAIL = createRequestTypes('GALLERY_DETAIL');
export const GALLERY_REFRESH = createRequestTypes('GALLERY_REFRESH');
export const LIKE_GALLERY = createRequestTypes('LIKE_GALLERY');
export const LIKE_PHOTO = createRequestTypes('LIKE_PHOTO');
export const GALLERY_COMMENT = createRequestTypes('GALLERY_COMMENT');
export const POST_GALLERY_COMMENT = createRequestTypes('POST_GALLERY_COMMENT');
export const REFRESH_GALLERY_COMMENT = createRequestTypes(
  'REFRESH_GALLERY_COMMENT'
);

export const deleteGallery = id => ({
  type: DELETE_GALLERY.REQUEST,
  id
})
export const updateGallery = (id, data) => ({
  type: UPDATE_GALLERY.REQUEST,
  id,
  data
});
export const createGallery = (data) => ({
  type: CREATE_GALLERY.REQUEST,
  data
})
export const getGalleries = (groupService, page = 1) => ({
  type: GET_GALLERY.REQUEST,
  groupService,
  page
});
export const galleryDetail = gallery => ({
  type: GALLERY_DETAIL.REQUEST,
  gallery
});
export const likeGallery = id => ({ type: LIKE_GALLERY.REQUEST, id });
export const likePhoto = id => ({ type: LIKE_PHOTO.REQUEST, id });
export const getComments = (id, page) => ({
  type: GALLERY_COMMENT.REQUEST,
  id,
  page
});
export const postComment = (id, content) => ({
  type: POST_GALLERY_COMMENT.REQUEST,
  id,
  content
});
export const refreshComment = id => ({
  type: REFRESH_GALLERY_COMMENT.REQUEST,
  id
});
