import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
// import GoogleMapReact from 'google-map-react';

import { Link, Redirect } from 'react-router-dom';
import CouponModal from '../../components/Coupon/CouponModal';

import { getCoupons, deleteCoupon } from '../../redux/actions/coupon';
import { getShops } from '../../redux/actions/shop';
import { COUPON_TYPE } from '../../configs/Constants';
import ShopForm from '../../components/Shop/ShopForm';
import Swal from 'sweetalert2';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export class Coupon extends Component {

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isCreating && !this.props.isCreating) {
      if(this.props.createResult && this.props.createResult.success) {
        Swal.fire("", "Cập nhật cửa hàng thành công!", 'success');
      }
      else {
        if(this.props.createResult && this.props.createResult.message) {
          Swal.fire("", this.props.createResult.message, 'error');
        }
        else {
          Swal.fire("", "Có lỗi xảy ra, vui lòng thử lại", 'error');
        }
      }
    }
  }

  componentDidMount() {
    this.props.getShops();
  }

  render() {
    const { shops, user } = this.props;
    if(!user || !user.isAdmin) {
      return <Redirect to="/" />
    }
    return <div className="container" style={{ marginTop: '80px' }}>
      <ShopForm />
    </div>
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  shops: state.shop.shops,
  isCreating: state.shop.isCreating,
  createResult: state.shop.createResult
});
const mapDispatchToProps = {
  getShops, deleteCoupon
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupon);