import { takeEvery, put } from 'redux-saga/effects';
import {
  DELETE_GALLERY,
  UPDATE_GALLERY,
  CREATE_GALLERY,
  GET_GALLERY,
  GALLERY_DETAIL,
  LIKE_GALLERY,
  LIKE_PHOTO,
  GALLERY_COMMENT,
  POST_GALLERY_COMMENT,
  REFRESH_GALLERY_COMMENT,
  GALLERY_REFRESH
} from '../actions/gallery';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosPut, axiosDelete } from './utils';
import queryString from 'query-string';

function* getGalleries(obj) {
  const result = yield axiosGet(
    API.GALLERY.GET +
      '?' +
      queryString.stringify({
        groupService: obj.groupService,
        page: obj.page
      })
  );
  if (result.success) {
    if(result.data.currentPage < result.data.totalPages) {
      yield put({
        type: GET_GALLERY.REQUEST,
        groupService: obj.groupService,
        page: obj.page + 1
      });
    }
    yield put({
      type: GET_GALLERY.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_GALLERY.FAIL,
      result
    });
  }
}

function* galleryDetail(obj) {
  const result = yield axiosGet(
    API.GALLERY.DETAIL.replace('{id}', obj.gallery)
  );
  if (result.success) {
    yield put({
      type: GALLERY_DETAIL.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GALLERY_DETAIL.FAIL,
      result
    });
  }
}

function* likeGallery(obj) {
  const result = yield axiosGet(
    API.GALLERY.LIKE_GALLERY.replace('{id}', obj.id)
  );
  if (result.success) {
    yield put({
      type: LIKE_GALLERY.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: LIKE_GALLERY.FAIL,
      result
    });
  }
}

function* likePhoto(obj) {
  const result = yield axiosGet(API.GALLERY.LIKE_PHOTO.replace('{id}', obj.id));
  if (result.success) {
    yield put({
      type: LIKE_PHOTO.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: LIKE_PHOTO.FAIL,
      result
    });
  }
}

function* getComments(obj) {
  const result = yield axiosGet(API.GALLERY.COMMENT.replace('{id}', obj.id));
  if (result.success) {
    yield put({
      type: GALLERY_COMMENT.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GALLERY_COMMENT.FAIL,
      result
    });
  }
}

function* postComment(obj) {
  const result = yield axiosPost(API.GALLERY.COMMENT.replace('{id}', obj.id), {
    content: obj.content
  });
  if (result.success) {
    yield put({
      type: POST_GALLERY_COMMENT.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: POST_GALLERY_COMMENT.FAIL,
      result
    });
  }
}

function* refreshComment(obj) {
  const result = yield axiosGet(API.GALLERY.COMMENT.replace('{id}', obj.id));
  if (result.success) {
    yield put({
      type: REFRESH_GALLERY_COMMENT.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: REFRESH_GALLERY_COMMENT.FAIL,
      result
    });
  }
}

function* createGallery(obj) {
  const { data } = obj;
  var formData = new FormData();
  if(data.heroImage) {
    formData.append("heroImage", data.heroImage);
  }
  formData.append("name", data.name);
  formData.append("groupServiceId", data.groupServiceId);
  formData.append("state", data.state);
  const result = yield axiosPost(API.GALLERY.CREATE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  if (result.success) {
    yield put({
      type: CREATE_GALLERY.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_GALLERY.REQUEST
    });
  } else {
    yield put({
      type: CREATE_GALLERY.FAIL,
      result
    });
  }
}

function* updateGallery(obj) {
  const { data } = obj;
  var formData = new FormData();
  if(data.heroImage) {
    formData.append("heroImage", data.heroImage);
  }
  
  formData.append("name", data.name);
  formData.append("groupServiceId", data.groupServiceId);
  formData.append("state", data.state);
  const result = yield axiosPut(API.GALLERY.DETAIL.replace('{id}', obj.id), formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  if (result.success) {
    yield put({
      type: UPDATE_GALLERY.SUCCESS,
      result: result.data
    });
    yield put({
      type: GALLERY_DETAIL.REQUEST,
      gallery: obj.id
    });
  } else {
    yield put({
      type: UPDATE_GALLERY.FAIL,
      result
    });
  }
}

function* deleteGallery(obj) {
  const result = yield axiosDelete(API.GALLERY.DETAIL.replace('{id}', obj.id));
  if (result.success) {
    yield put({
      type: DELETE_GALLERY.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_GALLERY.REQUEST
    });
  } else {
    yield put({
      type: DELETE_GALLERY.FAIL,
      result
    });
  }
}

export function* watchGallery() {
  yield takeEvery(DELETE_GALLERY.REQUEST, deleteGallery);
  yield takeEvery(UPDATE_GALLERY.REQUEST, updateGallery);
  yield takeEvery(CREATE_GALLERY.REQUEST, createGallery);
  yield takeEvery(GET_GALLERY.REQUEST, getGalleries);
  yield takeEvery(GALLERY_DETAIL.REQUEST, galleryDetail);
  yield takeEvery(GALLERY_REFRESH.REQUEST, galleryDetail);
  yield takeEvery(LIKE_GALLERY.REQUEST, likeGallery);
  yield takeEvery(LIKE_PHOTO.REQUEST, likePhoto);
  yield takeEvery(GALLERY_COMMENT.REQUEST, getComments);
  yield takeEvery(POST_GALLERY_COMMENT.REQUEST, postComment);
  yield takeEvery(REFRESH_GALLERY_COMMENT.REQUEST, refreshComment);
}
