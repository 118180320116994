import { GET_COUPON, FIND_COUPON, APPLY_COUPON, FIND_COUPON_AND_APPLY, UPDATE_COUPON, CREATE_COUPON, DELETE_COUPON, GET_COUPON_DETAIL } from '../actions/coupon';

const initialState = {
  isFetching: false,
  isFinding: false,
  isApplying: false,
  findSuccess: false,
  coupons: [],
  coupon: {},
  apply: {},
  applySuccess: false,
  update: {
    isFetching: false,
    success: null,
    result: null
  },
  create: {
    isFetching: false,
    success: null,
    result: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COUPON.REQUEST:
      return {
        ...state,
        
        isFetching: true
      };

    case GET_COUPON.SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.result
      };

    case GET_COUPON.FAIL: {
      return {
        ...state,
        coupons: [],
        isFetching: false
      };
    }

    case GET_COUPON_DETAIL.REQUEST:
      return {
        ...state,
        coupon: {},
        isFinding: true,
        findSuccess: null
      };

    case GET_COUPON_DETAIL.SUCCESS:
      return {
        ...state,
        isFinding: false,
        findSuccess: true,
        coupon: action.result
      };

    case GET_COUPON_DETAIL.FAIL: {
      return {
        ...state,
        isFinding: false,
        findSuccess: false
      };
    }

    case FIND_COUPON.REQUEST:
      return {
        ...state,
        coupon: {},
        isFinding: true,
        findSuccess: null
      };

    case FIND_COUPON.SUCCESS:
      return {
        ...state,
        isFinding: false,
        findSuccess: true,
        ...action.result
      };

    case FIND_COUPON.FAIL: {
      return {
        ...state,
        isFinding: false,
        findSuccess: false
      };
    }

    case FIND_COUPON_AND_APPLY.REQUEST:
      return {
        ...state,
        coupon: {},
        isFinding: true
      };

    case APPLY_COUPON.REQUEST:
      return {
        ...state,
        isApplying: true
      };

    case APPLY_COUPON.SUCCESS:
      return {
        ...state,
        isApplying: false,
        applySuccess: true
      };

    case APPLY_COUPON.FAIL: {
      return {
        ...state,
        isApplying: false,
        applySuccess: false
      };
    }

    case DELETE_COUPON.REQUEST:
      return {
        ...state,
        isDeleting: true
      };

    case DELETE_COUPON.SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true
      };

    case DELETE_COUPON.FAIL: {
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false
      };
    }

    case UPDATE_COUPON.REQUEST:
      return {
        ...state,
        update: {
          isFetching: true,
          success: null,
          result: null
        }
      };

    case UPDATE_COUPON.SUCCESS:
      return {
        ...state,
        update: {
          isFetching: false,
          success: true,
          result: action.result
        }
      };

    case UPDATE_COUPON.FAIL: {
      return {
        ...state,
        update: {
          isFetching: false,
          success: false,
          result: action.result
        }
      };
    }

    case CREATE_COUPON.REQUEST:
      return {
        ...state,
        create: {
          isFetching: true,
          success: null,
          result: null
        }
      };

    case CREATE_COUPON.SUCCESS:
      return {
        ...state,
        create: {
          isFetching: false,
          success: true,
          result: action.result
        }
      };

    case CREATE_COUPON.FAIL: {
      return {
        ...state,
        create: {
          isFetching: false,
          success: false,
          result: action.result
        }
      };
    }

    default:
      return state;
  }
};
