import {
  DELETE_GALLERY,
  UPDATE_GALLERY,
  CREATE_GALLERY,
  GET_GALLERY,
  GALLERY_DETAIL,
  GALLERY_REFRESH,
  LIKE_GALLERY,
  LIKE_PHOTO,
  GALLERY_COMMENT,
  POST_GALLERY_COMMENT,
  REFRESH_GALLERY_COMMENT,
} from "../actions/gallery";

const initialState = {
  isFetching: false,
  isFetchingPhoto: false,
  isFetchingComment: false,
  gallert: false,
  isRefreshingComment: false,
  isPostingComment: false,
  commentPage: 0,
  commentTotalPage: 0,
  galleries: [],
  photos: [],
  comments: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GALLERY.REQUEST:
      return {
        ...state,
        galleries: action.page == 1 ? [] : state.galleries,
        isFetching: true,
      };

    case GET_GALLERY.SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentPage: action.result.currentPage,
        totalPages: action.result.totalPages,
        galleries: state.galleries.concat(action.result.galleries),
      };

    case GET_GALLERY.FAIL: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case CREATE_GALLERY.REQUEST:
      return {
        ...state,
        isCreating: true,
        createResult: null,
      };

    case CREATE_GALLERY.SUCCESS:
      return {
        ...state,
        isCreating: false,
        createResult: action.result,
      };

    case CREATE_GALLERY.FAIL: {
      return {
        ...state,
        isCreating: false,
        createResult: action.result,
      };
    }

    case UPDATE_GALLERY.REQUEST:
      return {
        ...state,
        isUpdating: true,
        updateResult: null,
      };

    case UPDATE_GALLERY.SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateResult: action.result,
      };

    case UPDATE_GALLERY.FAIL: {
      return {
        ...state,
        isUpdating: false,
        updateResult: action.result,
      };
    }

    case DELETE_GALLERY.REQUEST:
      return {
        ...state,
        isDeleting: true,
        deleteResult: null,
      };

    case DELETE_GALLERY.SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteResult: action.result,
      };

    case DELETE_GALLERY.FAIL: {
      return {
        ...state,
        isDeleting: false,
        deleteResult: action.result,
      };
    }

    case GALLERY_DETAIL.REQUEST:
      return {
        ...state,
        photos: [],
        isFetchingPhoto: true,
      };

    case GALLERY_REFRESH.REQUEST:
      return {
        ...state,
        isFetchingPhoto: true,
      };

    case GALLERY_DETAIL.SUCCESS:
      return {
        ...state,
        isFetchingPhoto: false,
        ...action.result,
      };

    case GALLERY_DETAIL.FAIL: {
      return {
        ...state,
        isFetchingPhoto: false,
      };
    }

    /*
     * LIKE GALLERY
     */
    case LIKE_GALLERY.REQUEST: {
      const { id } = action;
      let galleries = Array.from(state.galleries);
      let gallery = galleries.find((item) => item.id === id);
      if (gallery) {
        gallery.isLiked = !gallery.isLiked;
      }

      return {
        ...state,
        galleries,
      };
    }

    case LIKE_GALLERY.SUCCESS:
      return {
        ...state,
      };

    case LIKE_GALLERY.FAIL: {
      return {
        ...state,
      };
    }

    /*
     * LIKE PHOTO
     */
    case LIKE_PHOTO.REQUEST: {
      const { id } = action;
      let photos = Array.from(state.photos);
      let photo = photos.find((item) => item.id === id);
      if (photo) {
        photo.isLiked = !photo.isLiked;
      }

      return {
        ...state,
        photos,
      };
    }

    case LIKE_PHOTO.SUCCESS:
      return {
        ...state,
      };

    case LIKE_PHOTO.FAIL: {
      return {
        ...state,
      };
    }

    /*
     *  GET GALLERY COMMENTS
     */
    case GALLERY_COMMENT.REQUEST:
      return {
        ...state,
        isFetchingComment: true,
        comments: [],
      };

    case GALLERY_COMMENT.SUCCESS:
      if (action.result.currentPage > state.currentPage) {
        return {
          ...state,
          commentPage: action.result.currentPage,
          commentTotalPage: action.result.totalPages,
          isFetchingComment: false,
          isRefreshingComment: false,
          comments: state.comments.concat(action.result.comments),
        };
      }
      return {
        ...state,
        isFetchingComment: false,
      };

    case GALLERY_COMMENT.FAIL:
      return {
        ...state,
        isFetchingComment: false,
      };

    /*
     *  POST GALLERY COMMENT
     */
    case POST_GALLERY_COMMENT.REQUEST:
      return {
        ...state,
        isPostingComment: true,
      };

    case POST_GALLERY_COMMENT.SUCCESS:
      return {
        ...state,
        isPostingComment: false,
        isRefreshingComment: false,
      };

    case POST_GALLERY_COMMENT.FAIL:
      return {
        ...state,
        isPostingComment: false,
      };

    /*
     *  Refresh blog comments
     */
    case REFRESH_GALLERY_COMMENT.REQUEST:
      return {
        ...state,
        isRefreshingComment: true,
      };

    case REFRESH_GALLERY_COMMENT.SUCCESS:
      return {
        ...state,
        isRefreshingComment: false,
        ...action.result,
      };

    case REFRESH_GALLERY_COMMENT.FAIL:
      return {
        ...state,
        isRefreshingComment: false,
      };

    default:
      return {
        ...state,
        isCreating: false,
        isUpdating: false,
      };
  }
};
