import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../redux/actions/login'

class Drawer extends Component {
  
    render() {
      const { user } = this.props;
      if(!user) {
        return <Redirect to="/login" push />
      }
      return (
        <div>
          <nav className="navbar navbar-expand-lg navbar-light" id="topbar">
            <a className="navbar-brand" href="/"><img className="logo" src="/images/logo.png" /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/schedule">Lịch đặt</Link>
                </li>
                {user && user.isAdmin &&
                <li className="nav-item">
                  <Link className="nav-link" to="/coupon">Mã giảm giá</Link>
                </li>
                }
                {/* {user && user.isAdmin &&
                <li className="nav-item">
                  <Link className="nav-link" to="/shop">Cửa hàng</Link>
                </li>
                } */}
                {user && (user.isAdmin || user.isSupport) && <li className="nav-item">
                  <Link className="nav-link" to="/conversation">Chat</Link>
                </li>
                }
                
                {user && user.isAdmin && <li className="nav-item">
                  <Link className="nav-link" to="/gallery">Bộ sưu tập</Link>
                </li>
                }
                {user && user.isAdmin && <li className="nav-item">
                  <Link className="nav-link" to="/schedule_notifications">Push thông báo</Link>
                </li>
                }
              </ul>
              <ul className="navbar-nav ml-auto">
              <li className="nav-item"><a href="#" className="nav-link" onClick={this.props.logout}>Đăng xuất</a></li>
              </ul>
            </div>
          </nav>
          {this.props.children}
          
        </div>
      );
    }
}

const mapStateToProps = state => ({
  user: state.login.user
});
const mapDispatchToProps = {
  logout
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Drawer);