import { GET_PROVINCES } from '../actions/province';

const initialState = {
  isFetching: false,
  province: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROVINCES.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case GET_PROVINCES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.result
      };

    case GET_PROVINCES.FAIL: {
      return {
        ...state,
        isFetching: false
      };
    }

    default:
      return state;
  }
};
