import {
  GET_CONVERSATIONS,
  SEND_MESSAGE
} from '../actions/conversation';

const initialState = {
  isFetching: false,
  data: [],
  isSendMessage: false,
  success: null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    
    case GET_CONVERSATIONS.REQUEST:
      return {
        ...state,
        isFetching: true,
        data: []
      };

    case GET_CONVERSATIONS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.result.conversations
      };

    case GET_CONVERSATIONS.FAIL:
      return {
        ...state,
        isFetching: false,
        data: []
      };

    case SEND_MESSAGE.REQUEST:
      return {
        ...state,
        isSendMessage: true,
        success: null,
      };

    case SEND_MESSAGE.SUCCESS:
      return {
        ...state,
        isSendMessage: false,
        success: true
      };

    case SEND_MESSAGE.FAIL:
      return {
        ...state,
        isSendMessage: false,
        success: false
      };


    default:
      return state;
  }
};