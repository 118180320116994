import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAvailabilities } from '../../redux/actions/booking';
import moment from 'moment';

import { findUsers } from '../../redux/actions/user';

class ScheduleTimeAvailablitityModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      q: null,
      date: moment().format('YYYY-MM-DD'),
      hour: ''
    };

    this.setTime = this.setTime.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  onChangeDate(ref) {
    this.setState({ date: ref.target.value }, () => {
      // this.props.getAvailabilities(this.props.shopId, this.state.date)
    })
  }
  
  setTime() {
    this.props.setTime(
     this.state.date,
     this.state.hour
    );
    window.$('#ScheduleTimeAvailablitityModal').modal('toggle')
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.date !== this.state.date) {
      this.props.getAvailabilities(this.props.shopId, this.state.date)
    }
  }

  static getDerivedStateFromProps(props, state) {
    
    if(props.schedule !== null && state.hour == '') {
      
      var time = moment(props.schedule.time, "DD/MM/YYYY HH:mm");
      return {
        date: time.format("YYYY-MM-DD"),
        hour: time.format("HH:mm")
      }
    }
    return null;
  }

  render() {
    const { times } = this.props;
    
    return (
    <div id="ScheduleTimeAvailablitityModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">Thời gian</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <label>Ngày</label>
                <input type="date" value={this.state.date} onChange={this.onChangeDate} className="form-control" />
              </div>
            </div>
            <div className="row">
              {times && times.map(time =>
              <div key={time.hour} className="col-md-6 mt-3">
                
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                    <input type="radio" id={time.hour} value={time.hour} name="hour" onChange={ref => this.setState({ hour: ref.target.value })} />
                    </div>
                  </div>
                  
                  <label htmlFor={time.available ? time.hour : ''} style={{ 
                      backgroundColor: '#e9ecef', 
                      flex: '1 1 auto',
                      backgroundClip: 'padding-box',
                      border: '1px solid #ced4da',
                      borderRadius: '.25rem',
                      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                      marginBottom: 0,
                      lineHeight: 1.5,
                      padding: '.375rem .75rem'
                    }}>{time.hour}</label>
                </div>
              </div>  
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={this.setTime}>Đã xong</button>
          </div>
        </div>
      </div>
    </div>
    )
  }
}


const mapStateToProps = state => ({
  findResults: state.user.findResults,
  times: state.booking.times
});
const mapDispatchToProps = {
  getAvailabilities
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleTimeAvailablitityModal);