import { createRequestTypes } from './utils';

export const GET_CONVERSATIONS = createRequestTypes('GET_CONVERSATIONS');
export const SEND_MESSAGE = createRequestTypes('SEND_MESSAGE');
export const SEND_IMAGE = createRequestTypes('SEND_IMAGE');
export const RECEIVED_MESSAGE = createRequestTypes('RECEIVED_MESSAGE');

export const getConversations = () => ({ type: GET_CONVERSATIONS.REQUEST });
export const sendMessage = (conversationId, content) => ({ type: SEND_MESSAGE.REQUEST, conversationId, content });
export const receivedMessage = messageId => ({
  type: RECEIVED_MESSAGE.REQUEST,
  messageId
});
export const sendImage = (filename, image, conversationId) => ({
  type: SEND_IMAGE.REQUEST,
  filename, image, conversationId
});
