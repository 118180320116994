import { createRequestTypes } from './utils';

export const GET_SERVICE = createRequestTypes('GET_SERVICE');
export const GET_GROUP_SERVICE = createRequestTypes('GET_GROUP_SERVICE');

export const getServices = (shopId, groupService = '') => ({
  type: GET_SERVICE.REQUEST,
  shopId,
  groupService
});
export const getGroupServices = () => ({ type: GET_GROUP_SERVICE.REQUEST });
