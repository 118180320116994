import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import { Link, Redirect } from 'react-router-dom';
import CouponModal from '../../components/Coupon/CouponModal';

import { getCoupons, deleteCoupon } from '../../redux/actions/coupon';
import { getPosts } from '../../redux/actions/post';
import { COUPON_TYPE } from '../../configs/Constants';
import PostForm from '../../components/Post/PostForm';

export class Coupon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentCoupon: {}
    };

    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.props.getPosts();
  }

  onDelete(id) {
    if(window.confirm("Bạn có chắc chắn muốn xóa mã giảm giá này")) {
      this.props.deleteCoupon(id);
    }
  }

  render() {
    const { posts, user } = this.props;
    if(!user || !user.isAdmin) {
      return <Redirect to="/" />
    }
    return <div className="container" style={{ marginTop: '80px' }}>
      <PostForm />
    </div>
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  posts: state.post.posts
});
const mapDispatchToProps = {
  getPosts, deleteCoupon
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupon);