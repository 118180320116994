import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import { Link, Redirect } from 'react-router-dom';
import CouponModal from '../../components/Coupon/CouponModal';

import { getCoupons, deleteCoupon } from '../../redux/actions/coupon';
import { getShops } from '../../redux/actions/shop';
import { COUPON_TYPE } from '../../configs/Constants';

export class Coupon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentCoupon: {}
    };

    this.onEdit = this.onEdit.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.props.getShops();
  }

  onEdit(coupon) {
    this.setState({
      currentCoupon: coupon
    }, () => {
      window.$('#CouponModal').modal('toggle');
    })
  }

  onDelete(id) {
    if(window.confirm("Bạn có chắc chắn muốn xóa mã giảm giá này")) {
      this.props.deleteCoupon(id);
    }
  }

  onAdd() {
    
  }

  render() {
    const { shops, user } = this.props;
    if(!user || !user.isAdmin) {
      return <Redirect to="/" />
    }
    return <div className="container" style={{ marginTop: '80px' }}>
      <h4>Danh sách cửa hàng</h4>
      <Link className="btn btn-link float-right" to={'shop/add'}>+ Tạo cửa hàng</Link>
      <table className="table table-bordered mt-3">
        <thead>
          <tr>
            <th>Hình ảnh</th>
            <th>Tên cửa hàng</th>
            <th>Địa chỉ</th>
            <th>Số điện thoại</th>
            <th>Thành phố</th>
            <th>Quận</th>
            <th>Mở cửa</th>
            <th>Đóng cửa</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {shops && shops.map(shop => 
            <tr key={shop.id}>
              <td>
                <img src={shop.image} style={{ height: 50 }} />
              </td>
              <td>
                <Link to={'/shop/' + shop.id + '/edit'}>
                {shop.title}
                </Link>
              </td>
              <td>
                {shop.address}
              </td>
              <td>
                {shop.phone}
              </td>
              <td>{shop.province}</td>
              <td>{shop.district}</td>
              <td>{shop.from}</td>
              <td>{shop.to}</td>
              <td className="fit">
                
                <Link to={'/shop/' + shop.id + '/edit'} className="btn btn-outline-primary">Sửa</Link>
                <button className="btn btn-outline-danger ml-3" onClick={() => this.onDelete(shop.id)}>Xóa</button>
              </td>
            </tr>
          )
          }
          
        </tbody>
      </table>
    </div>
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  shops: state.shop.shops
});
const mapDispatchToProps = {
  getShops, deleteCoupon
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupon);