import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../redux/actions/login';
import { Redirect } from 'react-router-dom';

class Login extends Component {

    constructor(props) {
      super(props);
      this.state = {
        showError: false,
        email: '',
        password: ''
      }
      this.onLogin = this.onLogin.bind(this);
    }

    onLogin() {
      this.setState({
        showError: false
      })
      this.props.login(this.state.email, this.state.password);
    }

    componentDidUpdate(prevProps, prevState) {
      
      if(prevProps.loginSuccess == null && this.props.loginSuccess !== prevProps.loginSuccess) {
        this.setState({
          showError: true,
          message: this.props.errorMessage
        })
      }
    }

    _handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        this.onLogin();
      }
    }

    render() {
      
      if(this.props.user) {
        return <Redirect to="/" />;
      }
        
      return (<div className="login-container">
          <div className="login-wrap">
          <div className="text-center mb-4">
            <img src="/images/logo.png" alt="" height="50" />
          </div>
          {this.state.showError &&
          <div className="alert alert-danger" role="alert">
            {this.state.message}
          </div>}
          <div className="form-group">
            <label htmlFor="inputEmail">Email:</label>
            <input type="email" id="inputEmail" className="form-control" placeholder="Email" required autoFocus 
            
            onChange={ref => this.setState({ email: ref.target.value })} onKeyDown={this._handleKeyDown}  />
          </div> 

          <div className="form-group">
            <label htmlFor="inputPassword">Mật khẩu:</label>
            <input type="password" id="inputPassword" className="form-control" placeholder="Password" required 
            
            onChange={ref => this.setState({ password: ref.target.value })} onKeyDown={this._handleKeyDown}  />
          </div>
          <button className="btn btn-lg btn-lamia btn-block" type="button" onClick={this.onLogin}>Đăng nhập</button>
          <p className="mt-3 text-muted text-center">Lamia &copy; 2019</p>
        </div>
      </div>);
    }
}

const mapStateToProps = state => ({
  user: state.login.user,
  loginSuccess: state.login.success,
  errorMessage: state.login.errorMessage
});
const mapDispatchToProps = {
  login
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);