import { combineReducers } from 'redux';
import login from './login';
import booking from './booking';
import schedule from './schedule';
import service from './service';
import data from './data';
import shop from './shop';
import user from './user';
import coupon from './coupon';
import conversation from './conversation';
import gallery from './gallery';
import photo from './photo';
import province from './province';
import post from './post';
import schedule_notification from './schedule_notification';

export default combineReducers({
  login,
  booking,
  schedule,
  service,
  data,
  shop,
  user,
  coupon,
  conversation,
  gallery,
  photo,
  province,
  post,
  schedule_notification
});
