import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { cancelSchedule, updateStatusSchedule } from '../../redux/actions/schedule';
import moment from 'moment';

class ScheduleModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      edit: false
    };

    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onDone = this.onDone.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    
    return {
      schedule: props.schedule
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.cancel && prevProps.cancel &&
       this.props.cancel.success !== prevProps.cancel.success && prevProps.cancel.success == null) {
      if(this.props.cancel.success == true) {
        window.$('#scheduleModal').modal('toggle')
      }
      else {
        alert("Có lỗi xảy ra, không thể hủy lịch");
      }
    }
    if(this.props.updateStatus && prevProps.updateStatus &&
      this.props.updateStatus.success !== prevProps.updateStatus.success && prevProps.updateStatus.success == null) {
      if(this.props.updateStatus.success == true) {
        window.$('#scheduleModal').modal('toggle')
      }
      else {
        alert("Có lỗi xảy ra, không thể cập nhật");
      }
    }
  }

  onCancel() {
    const { schedule } = this.props;
    this.props.cancelSchedule(schedule.id);
  }

  onConfirm() {
    const { schedule } = this.props;
    this.props.updateStatusSchedule(schedule.id, 'confirm');
  }

  onDone() {
    const { schedule } = this.props;
    this.props.updateStatusSchedule(schedule.id, 'done');
  }

  renderStatus(schedule) {
    if(schedule.isCanceled){
      return <p className="text-danger">Đã hủy</p>;
    }
    if(schedule.status === 'confirm'){
      return <p className="text-primary">Đã xác nhận đặt lịch</p>;
    }
    if(schedule.status === 'done'){
      return <p className="text-success">Hoàn thành</p>;
    }
    if(schedule.status === 'pending'){
      return <p className="text-secondary">Đang đợi xác nhận</p>;
    }
  }

  render() {
    const { schedule } = this.state;
    
    if(!schedule) {
      return null;
    }
    console.log(schedule)
    return (
    <div id="scheduleModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">Lịch hẹn</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <table className="table table-bordered table-hover">
              <tbody>
                <tr>
                  <th className="fit">Dịch vụ</th>
                  <td>
                    {schedule.service && schedule.service.map(service =>
                      <p key={service.id + Math.random()}>{service.name}</p>  
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Cửa hàng</th>
                  <td>{schedule.shop && schedule.shop.title}</td>
                </tr>
                <tr>
                  <th>Khách hàng:</th>
                  <td>
                    {schedule.user && <div>
                      <p>{schedule.user.name}</p>
                      <p>Email: <strong>{schedule.user.email}</strong></p>
                      <p>Phone: <strong>{schedule.user.phone}</strong></p>
                    </div>}
                  <p></p>
                  </td>
                </tr>
                <tr>
                  <th>Thời gian:</th>
                  <td>{schedule.time}</td>
                </tr>
                <tr>
                  <th>Trạng thái:</th>
                  <td>
                    {this.renderStatus(schedule)}
                  </td>
                </tr>
                <tr>
                  <th>Mã giảm giá:</th>
                  <td>
                    {schedule.coupon && schedule.coupon.code &&
                    <div>
                      <p>Mã giảm giá: <strong>{schedule.coupon.code}</strong></p>
                      <p>Tên: {schedule.coupon.name}</p>
                      <p>Giá trị: <strong>{schedule.coupon.discount_value} {schedule.coupon.discount_type == 'percent' ? '%' : ''}</strong></p>
                    </div>}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {
            schedule.status !== 'done'
            &&
            <div className="modal-footer">
              {!schedule.isCanceled && <button type="button" className="btn btn-danger mr-auto" onClick={this.onCancel}>Hủy lịch</button>}
              <Link to={'/schedule/' + schedule.id + '/edit'} className="btn btn-secondary" onClick={() => window.$('#scheduleModal').modal('toggle')}>Chỉnh sửa</Link>
              
              {(schedule.status === 'pending' || schedule.status === 'cancel') &&
              <button type="button" className="btn btn-primary" onClick={this.onConfirm}>Xác nhận đặt lịch</button>
              }
              {!schedule.isCanceled && schedule.status !== 'done' &&
              <button type="button" className="btn btn-success" onClick={this.onDone}>Xác nhận khách đã đến</button>
              }
            </div>
          }
          
        </div>
      </div>
    </div>
    )
  }
}


const mapStateToProps = state => ({
  cancel: state.schedule.cancel,
  updateStatus: state.schedule.updateStatus
});
const mapDispatchToProps = {
  cancelSchedule,
  updateStatusSchedule
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleModal);