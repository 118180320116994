import {
    SET_CURRENT_GROUP_SERVICE,
    SET_CURRENT_SHOP,
    REMOVE_CURRENT_SHOP,
    ADD_SERVICE,
    REMOVE_SERVICE,
    CLEAR_SERVICE,
    GET_AVAILABILITY,
    BOOKING,
    GET_BOOKINGS,
    UPDATE_BOOKING
  } from '../actions/booking';
  
  const initialState = {
    totalFrom: 0,
    totalTo: 0,
    serviceCount: 0,
    currentGroupService: '',
    currentShop: {},
    services: [],
    isRequestBooking: false,
    isFetchingTimes: false,
    times: [],
    bookingResult: {},
    data: []
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_CURRENT_GROUP_SERVICE:
        return {
          ...state,
          totalFrom: 0,
          totalTo: 0,
          serviceCount: 0,
          currentGroupService: action.groupService,
          services: []
        };
  
      case SET_CURRENT_SHOP:
        return {
          ...state,
          totalFrom: 0,
          totalTo: 0,
          serviceCount: 0,
          currentShop: action.shop,
          isRequestBooking: false,
          services: []
        };
  
      case REMOVE_CURRENT_SHOP:
        return {
          ...state,
          totalFrom: 0,
          totalTo: 0,
          serviceCount: 0,
          currentShop: {}
        };
  
      case ADD_SERVICE:
        return {
          ...state,
          services: state.services.concat(action.service),
          totalFrom: state.totalFrom + action.service.fromPrice,
          totalTo: state.totalTo + action.service.toPrice,
          serviceCount: state.serviceCount + 1
        };
  
      case REMOVE_SERVICE:
        const _s = state.services.find(
          service => service.id === action.serviceId
        );
        if (_s !== undefined) {
          return {
            ...state,
            services: state.services.filter(
              service => service.id !== action.serviceId
            ),
            totalFrom: state.totalFrom - _s.fromPrice,
            totalTo: state.totalTo - _s.toPrice,
            serviceCount: state.serviceCount - 1
          };
        }
        break;
  
      case CLEAR_SERVICE:
        return {
          ...state,
          totalFrom: 0,
          totalTo: 0,
          serviceCount: 0,
          services: []
        };
  
      case GET_AVAILABILITY.REQUEST:
        return {
          ...state,
          isFetchingTimes: true,
          times: []
        };
  
      case GET_AVAILABILITY.SUCCESS:
        return {
          ...state,
          isFetchingTimes: false,
          times: action.result.available
        };
  
      case GET_AVAILABILITY.FAIL:
        return {
          ...state,
          isFetchingTimes: false
        };
  
      case BOOKING.REQUEST:
        return {
          ...state,
          isRequestBooking: true
        };
  
      case BOOKING.SUCCESS:
        return {
          ...state,
          isRequestBooking: false,
          bookingResult: action.result
        };
  
      case BOOKING.FAIL:
        return {
          ...state,
          isRequestBooking: false,
          bookingResult: {
            error: true,
            message: 'Đã xả ra lỗi, vui lòng kiểm tra lại'
          }
        };

      case UPDATE_BOOKING.REQUEST:
        return {
          ...state,
          isRequestBooking: true
        };
  
      case UPDATE_BOOKING.SUCCESS:
        return {
          ...state,
          isRequestBooking: false,
          bookingResult: action.result
        };
  
      case UPDATE_BOOKING.FAIL:
        return {
          ...state,
          isRequestBooking: false,
          bookingResult: {
            error: true,
            message: 'Đã xả ra lỗi, vui lòng kiểm tra lại'
          }
        };
  
      default:
        return state;
    }
  };