import { createRequestTypes } from './utils';

export const GET_SHOP = createRequestTypes('GET_SHOP');
export const GET_SHOP_DETAIL = createRequestTypes('GET_SHOP_DETAIL');
export const GET_DAY_OF_WEEK = createRequestTypes('GET_DAY_OF_WEEK');
export const UPDATE_SHOP = createRequestTypes('UPDATE_SHOP');
export const CREATE_SHOP = createRequestTypes('CREATE_SHOP');
export const FAVORITE_SHOP = createRequestTypes('FAVORITE_SHOP');

export const getShops = (provinceId = '', districtId = '') => ({
  type: GET_SHOP.REQUEST,
  provinceId,
  districtId
});

export const getShopDetail = (id) => ({
  type: GET_SHOP_DETAIL.REQUEST,
  id
});

export const updateShop = (id, params) => ({
  type: UPDATE_SHOP.REQUEST,
  id,
  params
});

export const createShop = (params) => ({
  type: CREATE_SHOP.REQUEST,
  params
});

export const getDayOfWeek = () => ({
  type: GET_DAY_OF_WEEK.REQUEST,
});

export const favoriteShop = shopId => ({ type: FAVORITE_SHOP.REQUEST, shopId });
