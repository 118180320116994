import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import { Link, Redirect } from 'react-router-dom';
import { findUsers } from '../../redux/actions/user';
import { COUPON_TYPE, COUPON_DISCOUNT_TYPE } from '../../configs/Constants';

import { getCoupons, deleteCoupon, getCouponDetail, updateCoupon } from '../../redux/actions/coupon';

export class CouponUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      coupon: {},
      redirect: false
    };
    this.onSearchUser = this.onSearchUser.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if(id) {
      this.props.getCouponDetail(id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.coupon && (!this.state.coupon || this.props.coupon.id !== this.state.coupon.id)) {
      this.setState({
        coupon: {...this.props.coupon,
          start_at: moment(this.props.coupon.start_at).format('YYYY-MM-DD'),
          end_at: moment(this.props.coupon.end_at).format('YYYY-MM-DD')
        }
      })
    }
    if (this.props.update && this.props.update.success !== prevProps.update.success && prevProps.update.success == null) {
      if (this.props.update.success == true) {
        this.setState({
          redirect: true
        })
      }
      else {
        alert(this.props.update.result);
      }
    }

  }

  onSearchUser(ref) {
    this.props.findUsers(this.state.q);
  }

  removeUser(user) {
    this.setState({
      coupon: {
        ...this.state.coupon,
        users: this.state.coupon.users.filter(x => x.id !== user.id)
      }
    })
  }

  selectUser(user) {
    this.setState({
      coupon: {
        ...this.state.coupon,
        users: [...this.state.coupon.users, user]
      }
    })
  }

  onSave() {
    let { coupon } = this.state;

    if(!coupon.name) {
      alert('Vui lòng điền tên mã giảm giá');
      return;
    }

    if(!coupon.code) {
      alert('Vui lòng điền tên CODE giảm giá');
      return;
    }

    if(!coupon.discount_value) {
      alert('Vui lòng điền tên Giá trị giảm giá');
      return;
    }

    if(coupon.id !== 'new') {
      coupon = {...coupon, user_ids: coupon.users.map(x => x.id)}
      this.props.updateCoupon(coupon.id, coupon);
    }
    // else {
      
    //   this.props.createCoupon(coupon)
    // }
  }

  render() {
    const { findResults, user } = this.props;
    if(!user || !user.isAdmin) {
      return <Redirect to="/" />
    }
    const { coupon } = this.state;

    if(!coupon) {
      return <div/>
    }

    if(this.state.redirect) {
      return <Redirect to="/coupon" />
    }

    return <div style={{ margin: '80px' }}>
      
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12">
              <div className="input-group">
                <input type="text" onChange={(ref) => this.setState({ q: ref.target.value })} className="form-control" placeholder="Tìm kiếm khách hàng theo email hoặc sdt" />
                <button className="btn btn-primary ml-3" onClick={this.onSearchUser}>Tìm kiếm</button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered table-responsive-sm mt-3">
                <thead>
                  <tr>
                    <th>Tên</th>
                    <th>Email</th>
                    <th>SDT</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {findResults && findResults.map(user => 
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>
                        {coupon.users && coupon.users.find(u => u.id === user.id) &&
                        <button className="btn btn-outline-danger" onClick={() => this.removeUser(user)}>Xóa</button>
                        ||
                        <button className="btn btn-outline-primary" onClick={() => this.selectUser(user)}>Chọn</button>
                        }
                        
                      </td>
                    </tr>
                  )
                  }
                  
                </tbody>
              </table>
            </div>
          </div>
          {findResults.length === 0 &&
            <p>Không có kết quả nào</p>
          }
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-success ml-3 float-right" onClick={this.onSave}>Cập nhật</button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <label>Tên mã giảm giá:</label>
            </div>
            <div className="col-md-8">
              <input type="text" className="form-control" value={this.state.coupon.name || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, name: ref.target.value } })} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <label>Code:</label>
            </div>
            <div className="col-md-8">
              <input type="text" className="form-control" value={this.state.coupon.code || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, code: ref.target.value.toUpperCase() } })} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <label>Loại:</label>
            </div>
            <div className="col-md-8">
              <select className="form-control" value={this.state.coupon.type} onChange={ref => this.setState({ coupon: { ...this.state.coupon, type: ref.target.value } })} >
                {Object.keys(COUPON_TYPE).map(item =>
                  <option key={item} value={item}>{COUPON_TYPE[item]}</option>
                )}
              </select>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <label>Mô tả:</label>
            </div>
            <div className="col-md-8">
              <textarea className="form-control" value={this.state.coupon.description || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, description: ref.target.value } })}/>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <label>Giá trị:</label>
            </div>
            <div className="col-md-4">
              <input type="text" className="form-control" placeholder="Giá trị" value={this.state.coupon.discount_value || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, discount_value: ref.target.value } })} />
            </div>
            <div className="col-md-4">
              <select className="form-control" value={this.state.coupon.discount_type} onChange={ref => this.setState({ coupon: { ...this.state.coupon, discount_type: ref.target.value } })} >
                {Object.keys(COUPON_DISCOUNT_TYPE).map(item =>
                  <option key={item} value={item}>{COUPON_DISCOUNT_TYPE[item]}</option>
                )}
              </select>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <label>Thời gian bắt đầu:</label>
            </div>
            <div className="col-md-8">
              <input type="date" className="form-control" value={this.state.coupon.start_at || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, start_at: ref.target.value } })}  />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <label>Thời gian kết thúc:</label>
            </div>
            <div className="col-md-8">
              <input type="date" className="form-control" value={this.state.coupon.end_at || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, end_at: ref.target.value } })}  />
            </div>
          </div>
          <h5>Người dùng được hưởng coupon</h5>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Tên</th>
                <th>Email</th>
                <th>SDT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {coupon && coupon.users && coupon.users.map(user => 
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>
                    <button className="btn btn-outline-danger" onClick={() => this.removeUser(user)}>Xóa</button>
                  </td>
                </tr>
              )
              }
              
            </tbody>
          </table>
        </div>
        
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  coupon: state.coupon.coupon,
  findResults: state.user.findResults,
  update: state.coupon.update

});
const mapDispatchToProps = {
  getCoupons, deleteCoupon, getCouponDetail,
  findUsers, updateCoupon
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponUser);