import React, { Component } from "react";
import { connect } from "react-redux";
import ConversationDetail from "./ConversationDetail";
import firebase from "../../redux/firebase";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import {
  getConversations,
  sendMessage,
} from "../../redux/actions/conversation";
import { Redirect } from "react-router-dom";

import "./custom_conversation.css";
import moment from "moment";

export class Conversation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conversations: [],
      currentConversation: null,
    };
  }
  componentDidMount() {
    this.props.getConversations();

    // let db = firebase.firestore();
    const db = getFirestore(firebase);
    let doc = collection(db, "conversations");
    // .orderBy("updatedAt", "desc");
    const q = query(doc, orderBy("updatedAt", "desc"));
    let observer = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          this.setState({
            conversations: [...this.state.conversations, change.doc.data()],
          });
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.currentConversation == null &&
      this.state.conversations.length > 0
    ) {
      this.setState({
        currentConversation: this.state.conversations[0],
      });
    }
  }

  render() {
    const { user } = this.props;
    if (!(user.isAdmin || user.isSupport)) return <Redirect to="/" />;
    return (
      <div style={{ marginTop: "30px" }} className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="recent_heading">
              <h4>Recent</h4>
            </div>
          </div>
          <div className="col-md-9">
            <div className="recent_heading">
              <h4>
                {this.state.currentConversation &&
                  this.state.currentConversation.title}
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-3"
            style={{ maxHeight: "80vh", overflowY: "scroll" }}
          >
            <div className="inbox_people">
              {this.state.conversations.map((item) => (
                <div
                  key={item.id}
                  className={
                    "chat_list " +
                    (this.state.currentConversation &&
                    this.state.currentConversation.id === item.id
                      ? "active_chat"
                      : "")
                  }
                  onClick={() => this.setState({ currentConversation: item })}
                >
                  <div className="chat_people">
                    <div className="chat_img">
                      {" "}
                      <img
                        src="https://ptetutorials.com/images/user-profile.png"
                        alt="sunil"
                      />{" "}
                    </div>
                    <div className="chat_ib">
                      <h5>{item.title}</h5>
                      <span className="text-muted"></span>
                      <p>
                        {moment(item.updatedAt.toDate()).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="col-md-9"
            style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
          >
            <ConversationDetail
              conversation={this.state.currentConversation}
              key={Math.random()}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.login.user,
  conversations: state.conversation.data,
});
const mapDispatchToProps = {
  getConversations,
  sendMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
