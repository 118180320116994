import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateCoupon, createCoupon } from '../../redux/actions/coupon';
import moment from 'moment';
import { COUPON_TYPE, COUPON_DISCOUNT_TYPE } from '../../configs/Constants';


class CouponModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      coupon: {}
    };

    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  static getDerivedStateFromProps(props, state) {

    if (!state.coupon.id || state.coupon.id !== props.coupon.id) {
      
      return {
        coupon: {...props.coupon,
        start_at: moment(props.coupon.start_at).format('YYYY-MM-DD'),
        end_at: moment(props.coupon.end_at).format('YYYY-MM-DD')
        }
      }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.update && this.props.update.success !== prevProps.update.success && prevProps.update.success == null) {
      if (this.props.update.success == true) {
        window.$('#CouponModal').modal('toggle')
      }
      else {
        alert(this.props.update.result);
      }
    }

    if (this.props.create && this.props.create.success !== prevProps.create.success && prevProps.create.success == null) {
      if (this.props.create.success == true) {
        window.$('#CouponModal').modal('toggle')
      }
      else {
        alert(this.props.create.result);
      }
    }
  }

  onCancel() {
    const { schedule } = this.props;
    this.props.cancelSchedule(schedule.id);
  }

  onSave() {
    const { coupon } = this.state;

    if(!coupon.name) {
      alert('Vui lòng điền tên mã giảm giá');
      return;
    }

    if(!coupon.code) {
      alert('Vui lòng điền tên CODE giảm giá');
      return;
    }

    if(!coupon.discount_value) {
      alert('Vui lòng điền tên Giá trị giảm giá');
      return;
    }

    if(coupon.id !== 'new') {
      this.props.updateCoupon(coupon.id, coupon);
    }
    else {
      
      this.props.createCoupon(coupon)
    }
    
  }

  render() {
    

    return (
      <div id="CouponModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Mã giảm giá</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Tên mã giảm giá:</label>
                </div>
                <div className="col-md-8">
                  <input type="text" className="form-control" value={this.state.coupon.name || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, name: ref.target.value } })} />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Code:</label>
                </div>
                <div className="col-md-8">
                  <input type="text" className="form-control" value={this.state.coupon.code || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, code: ref.target.value.toUpperCase() } })} />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Loại:</label>
                </div>
                <div className="col-md-8">
                  <select className="form-control" value={this.state.coupon.type} onChange={ref => this.setState({ coupon: { ...this.state.coupon, type: ref.target.value } })} >
                    {Object.keys(COUPON_TYPE).map(item =>
                      <option key={item} value={item}>{COUPON_TYPE[item]}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Mô tả:</label>
                </div>
                <div className="col-md-8">
                  <textarea className="form-control" value={this.state.coupon.description || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, description: ref.target.value } })}/>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Giá trị:</label>
                </div>
                <div className="col-md-4">
                  <input type="text" className="form-control" placeholder="Giá trị" value={this.state.coupon.discount_value || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, discount_value: ref.target.value } })} />
                </div>
                <div className="col-md-4">
                  <select className="form-control" value={this.state.coupon.discount_type} onChange={ref => this.setState({ coupon: { ...this.state.coupon, discount_type: ref.target.value } })} >
                    {Object.keys(COUPON_DISCOUNT_TYPE).map(item =>
                      <option key={item} value={item}>{COUPON_DISCOUNT_TYPE[item]}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Thời gian bắt đầu:</label>
                </div>
                <div className="col-md-8">
                  <input type="date" className="form-control" value={this.state.coupon.start_at || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, start_at: ref.target.value } })}  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Thời gian kết thúc:</label>
                </div>
                <div className="col-md-8">
                  <input type="date" className="form-control" value={this.state.coupon.end_at || ''} onChange={ref => this.setState({ coupon: { ...this.state.coupon, end_at: ref.target.value } })}  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Hiển thị trên APP:</label>
                </div>
                <div className="col-md-8">
                  <input type="checkbox" className="form-control" checked={this.state.coupon.show_in_app || false} onChange={ref => this.setState({ coupon: { ...this.state.coupon, show_in_app: ref.target.checked } })}  />
                </div>
              </div>
              
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger mr-auto" onClick={this.onCancel}>Xóa</button>

              <button type="button" className="btn btn-primary" onClick={this.onSave}>Lưu</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  create: state.coupon.create,
  update: state.coupon.update
});
const mapDispatchToProps = {
  updateCoupon, createCoupon
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponModal);