import { GET_SERVICE } from '../actions/service';

const initialState = {
  isFetching: false,
  groups: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE.REQUEST:
      return {
        ...state,
        groups: [],
        isFetching: true
      };

    case GET_SERVICE.SUCCESS:
      const { service = [] } = action.result;
      const group = Array.from(
        new Set(service.map(item => item.groupService.name))
      );

      return {
        ...state,
        isFetching: false,
        groups: group.map(item => ({
          name: item,
          services: service.filter(s => s.groupService.name === item)
        }))
      };

    case GET_SERVICE.FAIL: {
      return {
        ...state,
        isFetching: false
      };
    }

    default:
      return state;
  }
};
