import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCGTdsKR7QlsN77XoxIaGRnAQuhE6bv7V0",
  authDomain: "lamiavn.firebaseapp.com",
  databaseURL: "https://lamiavn.firebaseio.com",
  projectId: "lamiavn",
  storageBucket: "lamiavn.appspot.com",
  messagingSenderId: "117138227620",
  appId: "1:117138227620:web:5f31e3889bf92799",
};
// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export default firebase;
