import { takeEvery, put } from 'redux-saga/effects';
import { GET_SCHEDULES, CANCEL_SCHEDULE, GET_SCHEDULE, UPDATE_STATUS_SCHEDULE } from '../actions/schedule';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosPut } from './utils';
import queryString from 'query-string';

function* getSchedules(obj) {
  const { month, year, groupServiceId, shopId, page } = obj;
  const result = yield axiosGet(
    API.SCHEDULE.LIST +
      '?' +
      queryString.stringify({
        month,
        year,
        groupServiceId,
        shopId, 
        page
      })
  );
  if (result.success) {
    yield put({
      type: GET_SCHEDULES.SUCCESS,
      result: result.data
    });

    const data = result.data;
    if(data.currentPage < data.totalPages) {
      yield put({
        type: GET_SCHEDULES.REQUEST,
        month, year, groupServiceId, shopId, page: data.currentPage + 1
      });
    }
  } else {
    yield put({
      type: GET_SCHEDULES.FAIL,
      result
    });
  }
}

function* getSchedule(obj) {
  
  const result = yield axiosGet(
    API.SCHEDULE.GET.replace('{id}', obj.id)
  );
  if (result.success) {
    yield put({
      type: GET_SCHEDULE.SUCCESS,
      result: result.data.schedule
    });
  } else {
    yield put({
      type: GET_SCHEDULE.FAIL,
      result
    });
  }
}

function* cancelSchedule(obj) {
  const result = yield axiosGet(
    API.SCHEDULE.CANCEL.replace('{id}', obj.id)
  );
  // console.log(result);
  if (result.success) {
    yield put({
      type: CANCEL_SCHEDULE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: CANCEL_SCHEDULE.FAIL,
      result
    });
  }
}

function* updateStatusSchedule(obj) {
  const result = yield axiosPut(
    API.SCHEDULE.STATUS.replace('{id}', obj.id),
    {
      status: obj.status
    }
  );
  // console.log(result);
  if (result.success) {
    yield put({
      type: UPDATE_STATUS_SCHEDULE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: UPDATE_STATUS_SCHEDULE.FAIL,
      result
    });
  }
}

export function* watchSchedule() {
  yield takeEvery(UPDATE_STATUS_SCHEDULE.REQUEST, updateStatusSchedule);
  yield takeEvery(GET_SCHEDULES.REQUEST, getSchedules);
  yield takeEvery(GET_SCHEDULE.REQUEST, getSchedule);
  yield takeEvery(CANCEL_SCHEDULE.REQUEST, cancelSchedule);
}