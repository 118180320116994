import React, { Component } from "react";
import { connect } from "react-redux";

import { getGroupServices } from "../../redux/actions/data";
import { createGallery, updateGallery } from "../../redux/actions/gallery";

class GalleryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      heroImage: "",
      name: "",
      groupServiceId: "",
      state: "draft",
    };

    this.onCreate = this.onCreate.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isCreating !== prevProps.isCreating &&
      prevProps.isCreating == true
    ) {
      if (this.props.createResult.success === true) {
        window.$("#galleryModal").modal("toggle");
      } else {
        alert("Có lỗi xảy ra, không thể tạo bộ sưu tập");
      }
    }
    if (
      this.props.isUpdating !== prevProps.isUpdating &&
      prevProps.isUpdating == true
    ) {
      if (this.props.updateResult.success === true) {
        window.$("#galleryModal").modal("toggle");
      } else {
        alert("Có lỗi xảy ra, không thể cập nhật bộ sưu tập");
      }
    }
    if (
      this.state.groupServiceId === "" &&
      this.props.groupServices.length > 0
    ) {
      this.setState({
        groupServiceId: this.props.groupServices[0].id,
      });
    }
    if (this.props.gallery && !this.state.id) {
      this.setState({
        ...this.props.gallery,
        heroImage: this.props.gallery.image,
      });
    }
  }

  componentDidMount() {
    this.props.getGroupServices();
    window.$("#galleryModal").on("hidden.bs.modal", function (event) {
      console.log("=========");
    });
  }

  onCreate() {
    if (this.state.id) {
      this.props.updateGallery(this.state.id, {
        name: this.state.name,
        heroImage: this.image.files[0],
        state: this.state.state,
        groupServiceId: this.state.groupServiceId,
      });
    } else {
      this.props.createGallery({
        name: this.state.name,
        heroImage: this.image.files[0],
        state: this.state.state,
        groupServiceId: this.state.groupServiceId,
      });
    }
  }

  onChangeImage = () => {
    const file = this.image.files[0];

    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        let image = new Image();
        image.src = reader.result;
        image.onload = () => {
          this.setState({
            heroImage: reader.result,
          });
        };
      },
      false
    );

    if (typeof file !== "undefined") {
      reader.readAsDataURL(file);
    }
  };

  render() {
    return (
      <div
        id="galleryModal"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Tạo bộ sưu tập
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4">
                  {this.state.heroImage && (
                    <img
                      src={this.state.heroImage || ""}
                      alt={this.state.name || ""}
                      className="w-100"
                    />
                  )}
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <label>Tên bộ sưu tập</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.name || ""}
                      onChange={(ref) =>
                        this.setState({ name: ref.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Ảnh đại diện</label>
                    <input
                      type="file"
                      ref={(ref) => (this.image = ref)}
                      className="form-control"
                      onChange={this.onChangeImage}
                    />
                  </div>
                  <div className="form-group">
                    <label>Group Service</label>
                    <select
                      className="form-control"
                      value={this.state.groupServiceId}
                      onChange={(ref) =>
                        this.setState({ groupServiceId: ref.target.value })
                      }
                    >
                      {this.props.groupServices &&
                        this.props.groupServices.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={this.state.state}
                      onChange={(ref) =>
                        this.setState({ state: ref.target.value })
                      }
                    >
                      <option value="draft">Draft</option>
                      <option value="published">Published</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {((this.props.isCreating || this.props.isUpdating) && (
                <p>Đang cập nhật...</p>
              )) || (
                <div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Hủy
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ml-3"
                    onClick={this.onCreate}
                  >
                    {this.state.id ? "Cập nhật" : "Tạo"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groupServices: state.data.groupServices,
  isCreating: state.gallery.isCreating,
  createResult: state.gallery.createResult,
  isUpdating: state.gallery.isUpdating,
  updateResult: state.gallery.updateResult,
});

const mapDispatchToProps = {
  createGallery,
  getGroupServices,
  updateGallery,
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryModal);
