import { takeEvery, put } from 'redux-saga/effects';
import { LOGIN, LOGIN_FACEBOOK, LOGIN_GOOGLE } from '../actions/login';
import axios from 'axios';
import API from '../../configs/API';
import { axiosPost } from './utils';
const queryString = require('query-string');

function axiosFacebook(accessToken) {
  return axios
    .get(API.AUTH.FACEBOOK + '?accessToken=' + encodeURI(accessToken))
    .then(response => {
      if (response.status === 200) {
        const { data } = response;
        return {
          success: true,
          token: data.accessToken,
          user: { ...data.user, user_id: data.user.id }
        };
      }
      return {
        success: false
      };
    })
    .catch(error => ({
      success: false,
      message: error.response
    }));
}

function axiosGoogle(accessToken) {
  return axios
    .get(API.AUTH.GOOGLE + '?accessToken=' + encodeURI(accessToken))
    .then(response => {
      if (response.status === 200) {
        const { data } = response;
        return {
          success: true,
          token: data.accessToken,
          user: { ...data.user, user_id: data.user.id }
        };
      }
      return {
        success: false
      };
    })
    .catch(error => ({
      success: false,
      message: error.response
    }));
}

function* loginFacebook(obj) {
  const result = yield axiosFacebook(obj.accessToken);
  if (result.success) {
    yield put({
      type: LOGIN.SUCCESS,
      result
    });
  } else {
    yield put({
      type: LOGIN.FAIL,
      result
    });
  }
}

function* loginGoogle(obj) {
  const result = yield axiosGoogle(obj.accessToken);
  if (result.success) {
    yield put({
      type: LOGIN.SUCCESS,
      result
    });
  } else {
    yield put({
      type: LOGIN.FAIL,
      result
    });
  }
}

function* regularLogin(obj) {
  const result = yield axiosPost(API.AUTH.LOGIN, {
    email: obj.email,
    password: obj.password
  });
  
  if (result.success) {
    const user = result.data.user;
    if(!user.isAdmin && !user.isManager && !user.isSupport) {
      yield put({
        type: LOGIN.FAIL,
        result: { error: "Access denied" }
      });
    }
    else {
      yield put({
        type: LOGIN.SUCCESS,
        result: {...result.data, token: result.data.accessToken}
      });
    }
    
  } else {
    
    yield put({
      type: LOGIN.FAIL,
      result: { error: result.errorMessage.response.data.message }
    });
  }
}

export function* watchLogin() {
  yield takeEvery(LOGIN_FACEBOOK, loginFacebook);
  yield takeEvery(LOGIN_GOOGLE, loginGoogle);
  yield takeEvery(LOGIN.REQUEST, regularLogin);
}
