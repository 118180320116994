import React, { Component } from "react";
import { connect } from "react-redux";
import numeral from "numeral";
import _ from "lodash/core";
import moment from "moment";

import { Redirect } from "react-router-dom";

import { getGroupServices, getServices } from "../../redux/actions/service";
import { getShops } from "../../redux/actions/shop";
import {
  getAvailabilities,
  booking,
  updateBooking,
} from "../../redux/actions/booking";
import { getSchedule } from "../../redux/actions/schedule";

import ScheduleUserModal from "../../components/Schedule/ScheduleUserModal";
import ScheduleTimeAvailablitityModal from "../../components/Schedule/ScheduleTimeAvailablitityModal";
import ScheduleCouponModal from "../../components/Schedule/ScheduleCouponModal";

class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedServices: [],
      shopId: null,
      groupServiceId: null,
      user: null,
      q: null,
      date: moment().format("YYYY-MM-DD"),
      hour: null,
      schedule: null,
      redirect: false,
    };

    this.onChangeShop = this.onChangeShop.bind(this);
    this.onAddService = this.onAddService.bind(this);
    this.onRemoveService = this.onRemoveService.bind(this);
    this.calPrice = this.calPrice.bind(this);
    this.openModalUser = this.openModalUser.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.openModalTime = this.openModalTime.bind(this);
    this.setTime = this.setTime.bind(this);
    this.onBooking = this.onBooking.bind(this);
    this.onSetRedirect = this.onSetRedirect.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.shopId == null) {
      const { user } = props;
      let shopId = props.shops && props.shops[0].id;

      if (user && user.shop && user.shop.length > 0) {
        shopId = user.shop[0];
      }
      return {
        shopId,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.shopId !== prevState.shopId) {
      this.props.getServices(this.state.shopId);
    }
    if (
      this.props.isRequestBooking !== prevProps.isRequestBooking &&
      prevProps.isRequestBooking == true
    ) {
      if (this.props.bookingResult.success) {
        this.setState(
          {
            schedule: this.props.bookingResult.schedule,
          },
          () => {
            window.$("#ScheduleCouponModal").modal("toggle");
          }
        );
      } else {
        alert("Có lỗi xảy ra, không thể tạo lịch hẹn");
      }
    }
    if (
      this.props.isScheduleFetching !== prevProps.isScheduleFetching &&
      prevProps.isScheduleFetching == true
    ) {
      var schedule = this.props.currentSchedule;

      var time = moment(schedule.time, "DD/MM/YYYY HH:mm");
      this.setState(
        {
          schedule,
          selectedServices: schedule.service
            ? schedule.service.filter((service) => service)
            : [],
          shopId: schedule.shop.id,
          user: schedule.user,
          hour: time.format("HH:mm"),
          date: time.format("YYYY-MM-DD"),
        },
        () => {
          this.props.getServices(this.state.shopId);
        }
      );
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.getSchedule(id);
    }
    this.props.getServices(this.state.shopId);
    // window.$('#ScheduleCouponModal').modal('toggle')
  }

  onChangeShop(ref) {
    this.setState(
      {
        shopId: ref.target.value,
        selectedServices: [],
      },
      () => {
        this.props.getServices(this.state.shopId, this.state.groupServiceId);
      }
    );
  }

  onAddService(service) {
    const { selectedServices } = this.state;

    if (selectedServices.find((item) => item.id === service.id) !== undefined) {
      return;
    }
    this.setState({
      selectedServices: [...this.state.selectedServices, service],
    });
  }

  onRemoveService(service) {
    this.setState({
      selectedServices: this.state.selectedServices.filter(
        (item) => (item.id || item._id) != service.id
      ),
    });
  }

  calPrice() {
    var fromTotal = 0;
    var toTotal = 0;

    this.state.selectedServices.map((service) => {
      if (!service) return;
      fromTotal += service.fromPrice;
      toTotal += service.toPrice !== undefined ? service.toPrice : 0;
    });

    return (
      numeral(fromTotal).format("0,0") +
      (toTotal !== 0 ? " - " + numeral(toTotal).format("0,0") : "")
    );
  }

  openModalUser() {
    window.$("#ScheduleUserModal").modal("toggle");
  }

  openModalTime() {
    this.props.getAvailabilities(this.state.shopId, this.state.date);
    window.$("#ScheduleTimeAvailablitityModal").modal("toggle");
  }

  selectUser(user) {
    this.setState({
      user,
    });
  }

  setTime(date, hour) {
    this.setState({
      date,
      hour,
    });
  }

  onBooking() {
    const { user, selectedServices, date, hour, shopId } = this.state;
    if (!shopId) {
      alert("Vui lòng chọn cửa hàng");
      return;
    }

    if (!user) {
      alert("Vui lòng chọn khách hàng");
      return;
    }

    if (!date || !hour) {
      alert("Vui lòng chọn thời gian");
      return;
    }

    if (selectedServices.length == 0) {
      alert("Vui lòng chọn dịch vụ");
      return;
    }

    if (this.state.schedule && this.state.schedule.id) {
      this.props.updateBooking(
        this.state.schedule.id,
        shopId,
        selectedServices.map((service) => service.id || service._id),
        date + " " + hour,
        user.id
      );
    } else {
      this.props.booking(
        shopId,
        selectedServices.map((service) => service.id || service._id),
        date + " " + hour,
        user.id
      );
    }
  }

  onSetRedirect() {
    this.setState({
      redirect: true,
    });
  }

  render() {
    const { groups, groupServices, shops, findResults } = this.props;

    if (this.state.redirect) {
      return <Redirect to="/schedule" />;
    }

    return (
      <div
        style={{ flexGrow: 1 }}
        className="container"
        style={{ marginTop: "80px" }}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Cửa hàng: </label>
              <select
                className="form-control"
                value={this.state.shopId}
                onChange={this.onChangeShop}
              >
                {shops &&
                  shops.map((shop) => (
                    <option key={shop.id} value={shop.id}>
                      {shop.title}
                    </option>
                  ))}
              </select>
            </div>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Tên dịch vụ</th>
                  <th>Giá</th>
                  <th>Tác vụ</th>
                </tr>
              </thead>
              <tbody>
                {groups &&
                  groups.map((group) => [
                    <tr key={group.id + Math.random()}>
                      <td colSpan="3">
                        <strong>{group.name}</strong>
                      </td>
                    </tr>,
                    group.services &&
                      group.services.map((service) => (
                        <tr key={service.id}>
                          <td>{service.name}</td>
                          <td>
                            {numeral(service.fromPrice).format("0,0")}
                            {service.toPrice &&
                              " - " + numeral(service.toPrice).format("0,0")}
                          </td>
                          <td>
                            {(this.state.selectedServices &&
                              this.state.selectedServices.find(
                                (item) =>
                                  item && (item.id || item._id) === service.id
                              ) === undefined && (
                                <button
                                  className="btn btn-outline-primary"
                                  onClick={() => this.onAddService(service)}
                                >
                                  Thêm
                                </button>
                              )) || (
                              <button
                                className="btn btn-outline-danger"
                                onClick={() => this.onRemoveService(service)}
                              >
                                Xoá
                              </button>
                            )}
                          </td>
                        </tr>
                      )),
                  ])}
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <h5>Thông tin lịch hẹn</h5>
            <ScheduleUserModal selectUser={this.selectUser} />
            <ScheduleTimeAvailablitityModal
              schedule={this.state.schedule}
              shopId={this.state.shopId}
              setTime={this.setTime}
            />
            <ScheduleCouponModal
              schedule={this.state.schedule}
              onSetRedirect={this.onSetRedirect}
            />
            <hr />
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-primary float-right"
                  onClick={this.onBooking}
                >
                  {this.state.schedule ? "Cập nhật lịch hẹn" : "Đặt lịch hẹn"}
                </button>
              </div>
            </div>
            <table className="table mt-3">
              <tbody>
                <tr>
                  <td>Cửa hàng:</td>
                  <td>
                    <strong>
                      {shops &&
                        this.state.shopId &&
                        shops.find((shop) => shop.id === this.state.shopId)
                          .name}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Khách hàng:</td>
                  <td>
                    {(this.state.user && [
                      <strong key={this.state.user.id}>
                        {this.state.user.name} - {this.state.user.email} -{" "}
                        {this.state.user.phone}
                      </strong>,
                      <button
                        key={"btn" + this.state.user.id}
                        className="btn btn-link text-danger"
                        onClick={() => this.setState({ user: null })}
                      >
                        Xóa
                      </button>,
                    ]) || (
                      <button
                        className="btn btn-link"
                        onClick={this.openModalUser}
                      >
                        Chọn khách hàng
                      </button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Thời gian:</td>
                  <td>
                    {this.state.date && this.state.hour && (
                      <p>
                        {this.state.date} - {this.state.hour}
                      </p>
                    )}
                    <button
                      className="btn btn-link"
                      onClick={this.openModalTime}
                    >
                      Chọn thời gian
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Tổng số dịch vụ:</td>
                  <td>
                    {this.state.selectedServices && (
                      <strong>{this.state.selectedServices.length}</strong>
                    )}
                  </td>
                </tr>
                {this.state.schedule && (
                  <tr>
                    <td>Mã giảm giá:</td>
                    <td>
                      {this.state.schedule.coupon && [
                        <p>
                          Mã giảm giá:{" "}
                          <strong>{this.state.schedule.coupon.code}</strong>
                        </p>,
                        <p>Tên: {this.state.schedule.coupon.name}</p>,
                        <p>
                          Giá trị:{" "}
                          <strong>
                            {this.state.schedule.coupon.discount_value}{" "}
                            {this.state.schedule.coupon.discount_type ==
                            "percent"
                              ? "%"
                              : ""}
                          </strong>
                        </p>,
                      ]}
                      <button
                        className="btn btn-link"
                        onClick={() =>
                          window.$("#ScheduleCouponModal").modal("toggle")
                        }
                      >
                        Sửa mã giảm giá
                      </button>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Tổng chi phí:</td>
                  <td>
                    <strong>{this.calPrice()}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Tên dịch vụ</th>
                  <th>Giá</th>
                  <th>Tác vụ</th>
                </tr>
              </thead>
              <tbody>
                {this.state.selectedServices &&
                  this.state.selectedServices.map((service) => (
                    <tr>
                      <td>{service.name}</td>
                      <td>
                        {numeral(service.fromPrice).format("0,0")}
                        {service.toPrice &&
                          " - " + numeral(service.toPrice).format("0,0")}
                      </td>
                      <td>
                        <button className="btn btn-outline-danger">Xoá</button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.login.user,
  schedules: state.schedule.data,
  groupServices: state.data.groupServices,
  shops: state.shop.shops,
  groups: state.service.groups,
  findResults: state.user.findResults,
  isRequestBooking: state.booking.isRequestBooking,
  bookingResult: state.booking.bookingResult,
  isScheduleFetching: state.schedule.isFetching,
  currentSchedule: state.schedule.currentSchedule,
});
const mapDispatchToProps = {
  getServices,
  getGroupServices,
  getShops,
  getAvailabilities,
  booking,
  getSchedule,
  updateBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
