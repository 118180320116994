import { createRequestTypes } from './utils';

export const SET_CURRENT_GROUP_SERVICE = 'SET_CURRENT_GROUP_SERVICE';
export const SET_CURRENT_SHOP = 'SET_CURRENT_SHOP';
export const REMOVE_CURRENT_SHOP = 'REMOVE_CURRENT_SHOP';
export const ADD_SERVICE = 'ADD_SERVICE';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export const CLEAR_SERVICE = 'CLEAR_SERVICE';

export const GET_AVAILABILITY = createRequestTypes('GET_AVAILABILITY');
export const BOOKING = createRequestTypes('BOOKING');

export const GET_BOOKING = createRequestTypes('GET_BOOKINGS');
export const UPDATE_BOOKING = createRequestTypes('UPDATE_BOOKING');


export const setCurrentGroupService = groupService => ({
  type: SET_CURRENT_GROUP_SERVICE,
  groupService
});
export const setCurrentShop = shop => ({ type: SET_CURRENT_SHOP, shop });
export const removeCurrentShop = () => ({ type: REMOVE_CURRENT_SHOP });
export const addService = service => ({ type: ADD_SERVICE, service });
export const removeService = serviceId => ({ type: REMOVE_SERVICE, serviceId });
export const clearService = () => ({ type: CLEAR_SERVICE });

export const getAvailabilities = (shopId, date) => ({
  type: GET_AVAILABILITY.REQUEST,
  shopId,
  date
});
export const booking = (shopId, serviceIds, time, userId) => ({
  type: BOOKING.REQUEST,
  shopId,
  serviceIds,
  time,
  userId
});

export const updateBooking = (id, shopId, serviceIds, time, userId) => ({
  type: UPDATE_BOOKING.REQUEST,
  id, shopId, serviceIds, time, userId
});