import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import { Link, Redirect } from 'react-router-dom';
import CouponModal from '../../components/Coupon/CouponModal';

import { getCoupons, deleteCoupon } from '../../redux/actions/coupon';
import { getPosts } from '../../redux/actions/post';
import { COUPON_TYPE } from '../../configs/Constants';

export class Coupon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentCoupon: {}
    };

    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.props.getPosts();
  }

  onDelete(id) {
    if(window.confirm("Bạn có chắc chắn muốn xóa mã giảm giá này")) {
      this.props.deleteCoupon(id);
    }
  }

  render() {
    const { posts, user } = this.props;
    if(!user || !user.isAdmin) {
      return <Redirect to="/" />
    }
    return <div className="container" style={{ marginTop: '80px' }}>
      <h4>Danh sách bài viết</h4>
      <Link className="btn btn-link float-right" to={'post/add'}>+ Thêm bài viết</Link>
      <table className="table table-bordered mt-3">
        <thead>
          <tr>
            <th>Hình ảnh</th>
            <th>Tên bài viết</th>
            <th>Thời gian</th>
            <th>Trạng thái</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {posts && posts.map(post => 
            <tr key={post.id}>
              <td>
                <img src={post.image} style={{ height: 50 }} />
              </td>
              <td>
                <Link to={'/post/' + post.id + '/edit'}>
                {post.title}
                </Link>
              </td>
              <td>{post.publishedDate}</td>
              <td>{post.state}</td>
              <td className="fit">
                
                <Link to={'/post/' + post.id + '/edit'} className="btn btn-outline-primary">Sửa</Link>
                <button className="btn btn-outline-danger ml-3" onClick={() => this.onDelete(post.id)}>Xóa</button>
              </td>
            </tr>
          )
          }
          
        </tbody>
      </table>
    </div>
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  posts: state.post.posts
});
const mapDispatchToProps = {
  getPosts, deleteCoupon
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupon);