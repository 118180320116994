import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import moment from 'moment';
import { getScheduleNotifications, deleteScheduleNotification } from '../../redux/actions/schedule_notification';
import NotificationModal from '../../components/ScheduleNotification/NotificationModal';

class ScheduleNotification extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
    }
    this.onAdd = this.onAdd.bind(this);
  }

  componentDidMount() {
   this.props.getScheduleNotifications();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.delete && this.props.delete.success !== prevProps.delete.success && prevProps.delete.success == null) {
      if (this.props.delete.success == true) {
        this.props.getScheduleNotifications();
      }
      else {
        alert(this.props.delete.result);
      }
    }
  }

  onAdd() {
    this.setState({
      
    }, () => {
      window.$('#NotificationModal').modal('toggle');
    })
  }

  onDelete(id) {
    if(window.confirm("Bạn có chắc chắn muốn xóa?")) {
      this.props.deleteScheduleNotification(id);
    }
  }
  
  render() {
    const { notifications, user } = this.props;

    return <div style={{ flexGrow: 1 }} className="container" style={{ marginTop: '80px' }}>
      <NotificationModal />
      <div className="row">
        <div className="col">
          <button className="btn btn-link float-right" onClick={this.onAdd}>+ Tạo lịch push thông báo</button>
        </div>
      </div>
      
      <div className="row">
        <div className="col-md-12">
          <table className="table table-bordered">
            <thead>
              <tr>
                <td>#</td>
                <td>Tiêu đề</td>
                <td>Nội dung</td>
                <td>Push vào lúc</td>
                <td>Trạng thái</td>
                <td>Tác vụ</td>
              </tr>
            </thead>
            <tbody>
              {notifications && notifications.map((notification, index) => 
              <tr>
                <td>{index + 1 }</td>
                <td>{notification.title}</td>
                <td>{notification.body}</td>
                <td className="fit">{moment(notification.push_at).format('YYYY-MM-DD hh:mm a')}</td>
                <td>{notification.status}</td>
                <td>
                  <button className="btn btn-link text-danger" onClick={() => this.onDelete(notification.id)}>
                    <span className="fa fa-close"></span> Xóa
                  </button>
                </td>
              </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  notifications: state.schedule_notification.notifications,
  delete: state.schedule_notification.delete
});
const mapDispatchToProps = {
  getScheduleNotifications,
  deleteScheduleNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleNotification);