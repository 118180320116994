import { createRequestTypes } from "./utils";
// import { create } from 'handlebars';

export const UPDATE_STATUS_SCHEDULE = createRequestTypes(
  "UPDATE_STATUS_SCHEDULE"
);
export const GET_SCHEDULES = createRequestTypes("GET_SCHEDULES");
export const CANCEL_SCHEDULE = createRequestTypes("CANCEL_SCHEDULE");
export const GET_SCHEDULE = createRequestTypes("GET_SCHEDULE");

export const updateStatusSchedule = (id, status) => ({
  type: UPDATE_STATUS_SCHEDULE.REQUEST,
  id,
  status,
});

export const getSchedules = (
  month,
  year,
  groupServiceId,
  shopId,
  page = 1
) => ({
  type: GET_SCHEDULES.REQUEST,
  month,
  year,
  groupServiceId,
  shopId,
  page,
});

export const cancelSchedule = (id) => ({
  type: CANCEL_SCHEDULE.REQUEST,
  id,
});

export const getSchedule = (id) => ({
  type: GET_SCHEDULE.REQUEST,
  id,
});
