import { takeEvery, put } from 'redux-saga/effects';
import { GET_AVAILABILITY, BOOKING, UPDATE_BOOKING } from '../actions/booking';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosPut } from './utils';
import queryString from 'query-string';
import { existsTypeAnnotation } from '@babel/types';

function* getAvailabilities(obj) {
  const { shopId, date } = obj;
  const result = yield axiosGet(
    API.BOOKING.AVAILABILITY +
      '?' +
      queryString.stringify({
        shopId,
        date
      })
  );
  if (result.success) {
    yield put({
      type: GET_AVAILABILITY.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_AVAILABILITY.FAIL,
      result
    });
  }
}

function* booking(obj) {
  const { shopId, time, serviceIds, userId } = obj;
  const result = yield axiosPost(API.BOOKING.BOOK, {
    shopId,
    time,
    serviceIds,
    userId
  });
  if (result.success) {
    yield put({
      type: BOOKING.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: BOOKING.FAIL,
      result
    });
  }
}

function* updateBooking(obj) {
  const { id, shopId, time, serviceIds, userId } = obj;
  
  const result = yield axiosPut(API.BOOKING.UPDATE_BOOK.replace('{id}', id), {
    shopId,
    time,
    serviceIds,
    userId
  });
  if (result.success) {
    yield put({
      type: UPDATE_BOOKING.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: UPDATE_BOOKING.FAIL,
      result
    });
  }
}

export function* watchBooking() {
  yield takeEvery(GET_AVAILABILITY.REQUEST, getAvailabilities);
  yield takeEvery(BOOKING.REQUEST, booking);
  yield takeEvery(UPDATE_BOOKING.REQUEST, updateBooking);
}