import { takeEvery, put } from 'redux-saga/effects';
import { GET_GROUP_SERVICE } from '../actions/data';
import API from '../../configs/API';
import { axiosGet } from './utils';
import queryString from 'query-string';

function* getGroupServices() {
  const result = yield axiosGet(API.DATA.GROUP_SERVICE);
  if (result.success) {
    yield put({
      type: GET_GROUP_SERVICE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_GROUP_SERVICE.FAIL,
      result
    });
  }
}

export function* watchData() {
  yield takeEvery(GET_GROUP_SERVICE.REQUEST, getGroupServices);
}
