import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateShop, createShop } from '../../redux/actions/shop'

export default (props) => {
  const dispatch = useDispatch();
  const [shop, setShop] = useState({});
  const [districts, setDistricts] = useState([]);

  const provinces = useSelector(state => state.province.provinces);
  let dayOfWeeks = useSelector(state => state.shop.dayOfWeeks);
  dayOfWeeks = dayOfWeeks ? dayOfWeeks.map(item => ({ value: item.id, label: item.name })) : []
  console.log(dayOfWeeks)

  const refreshDistrict = () => {
    let found = provinces.find(item => item.id == shop.province_id);
    console.log(found, shop.province_id)
    if(found) {
      setDistricts(found.districts);
    }
  }

  let workDays = [];
  if(shop && shop.work_day_ids) {
    
    shop.work_day_ids.map(item => {
      let found = dayOfWeeks.find(x => x.value == item);
      if(found) {
        workDays.push({ value: item, label: found.label })
      }
    })
  }

  useEffect(() => {
    refreshDistrict();
  }, [shop.province_id])

  useEffect(() => {
    if(props.shop) {
      setShop(props.shop)
      refreshDistrict();
    }
    else {
      if(provinces && provinces.length > 0) {
        setShop({ ...shop, province_id: provinces[0].id })
      }
    }
  }, [props.shop])

  const onUpdate = () => {
    if(shop.id) {
      dispatch(updateShop(shop.id, {
        ...shop, 
        workDay: shop.work_day_ids,
        district: shop.district_id,
        province: shop.province_id
      }))
    }
    else {
      dispatch(createShop({
        ...shop, 
        workDay: shop.work_day_ids,
        district: shop.district_id,
        province: shop.province_id
      }))
    }
  }

  return (
    <table className="table">
      <tbody>
        <tr>
          <th>Hình ảnh</th>
          <td>
            <div className="row">
              <div className="col-md-6">
                <input type="file" />
              </div>
              <div className="col-md-6">
                <img src={shop.image} style={{ width: 150 }} />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>Tên cửa hàng</th>
          <th>
            <input type="text" className="form-control" placeholder="Tên cửa hàng" value={shop.title || ''} onChange={ref => setShop({ ...shop, title: ref.target.value })} />
          </th>
        </tr>
        <tr>
          <th>Mô tả</th>
          <td>
            <textarea className="form-control" placeholder="Mô tả" value={shop.description || ''} onChange={ref => setShop({ ...shop, description: ref.target.value })}  />
          </td>
        </tr>
        <tr>
          <th>Số điện thoại</th>
          <td>
            <input type="text" className="form-control" placeholder="Số điện thoại" value={shop.phone || ''} onChange={ref => setShop({ ...shop, phone: ref.target.value })} />
          </td>
        </tr>
        <tr>
          <th>Địa chỉ</th>
          <td>
            <input type="text" className="form-control" placeholder="Địa chỉ" value={shop.address || ''} onChange={ref => setShop({ ...shop, address: ref.target.value })} />
          </td>
        </tr>
        <tr>
          <th>Thành phố</th>
          <td>
            <div className="row">
              <div className="col-md-6">
                <label><strong>Thành phố</strong></label>
                <select className="form-control" value={shop.province_id || ''} onChange={ref => setShop({ ...shop, province_id: ref.target.value })}>
                  {provinces && provinces.map(province => 
                    <option value={province.id}>{province.name}</option>
                  )}
                </select>
              </div>
              <div className="col-md-6">
                <label><strong>Quận</strong></label>
                <select className="form-control" value={shop.district_id || ''} onChange={ref => setShop({ ...shop, district_id: ref.target.value })}>
                  {districts && districts.map(district => 
                  <option value={district.id}>{district.name}</option>  
                  )}
                </select>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>Thời gian mở cửa</th>
          <td>
            <div className="row">
              <div className="col-md-6">
                <label><strong>Từ</strong></label>
                <input type="text" className="form-control" placeholder="Mở cửa từ" value={shop.from || ''} onChange={ref => setShop({ ...shop, from: ref.target.value })} />
              </div>
              <div className="col-md-6">
                <label><strong>Đến</strong></label>
                <input type="text" className="form-control" placeholder="Mở cửa từ" value={shop.to || ''} onChange={ref => setShop({ ...shop, to: ref.target.value })} />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>Ngày làm việc</th>
          <td>
            <Select
              value={workDays || []}
              onChange={selectedOption => setShop({ ...shop, work_day_ids: selectedOption.map(item => item.value)})}
              isMulti
              options={dayOfWeeks}
            />
            
          </td>
        </tr>
        <tr>
          <th>Đóng cửa</th>
          <td>
            <div className="row">
              <div className="col-md-6">
                <label><strong>Từ</strong></label>
                <input type="date" className="form-control" placeholder="Đóng cửa cửa từ" value={shop.off_from || ''} onChange={ref => setShop({ ...shop, off_from: ref.target.value })} />
              </div>
              <div className="col-md-6">
                <label><strong>Đến</strong></label>
                <input type="date" className="form-control" placeholder="Đóng cửa từ" value={shop.off_to || ''} onChange={ref => setShop({ ...shop, off_to: ref.target.value })} />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>Map</th>
          <td>
            <div className="row">
              <div className="col-md-6">
                <label><strong>Kinh độ</strong></label>
                <input type="text" className="form-control" placeholder="Kinh độ" value={shop.lat || ''} onChange={ref => setShop({ ...shop, lat: ref.target.value })} />
              </div>
              <div className="col-md-6">
                <label><strong>Vĩ độ</strong></label>
                <input type="text" className="form-control" placeholder="Vĩ độ" value={shop.lon || ''} onChange={ref => setShop({ ...shop, lon: ref.target.value })} />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <button className="btn btn-primary" onClick={onUpdate}>Cập nhật</button>
          </td>
        </tr>
      </tbody>
    </table>
  )
}