import {
    GET_SCHEDULES,
    CANCEL_SCHEDULE,
    GET_SCHEDULE,
    UPDATE_STATUS_SCHEDULE
  } from '../actions/schedule';
  
  const initialState = {
    isFetching: false,
    data: [],
    currentSchedule: {},
    cancel: {
      isFetching: false,
      success: null,
      result: null
    }
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      
      case GET_SCHEDULES.REQUEST:
        return {
          ...state,
          isFetching: true
        };
  
      case GET_SCHEDULES.SUCCESS:
        if(action.result.currentPage > state.currentPage) {
          return {
            ...state,
            isFetching: false,
            data: state.data.concat(action.result.schedules),
            currentPage: action.result.currentPage,
            totalPages: action.result.totalPages
          };
        }
        return {
          ...state,
          isFetching: false,
          data: action.result.schedules,
          currentPage: action.result.currentPage,
          totalPages: action.result.totalPages
        };
  
      case GET_SCHEDULES.FAIL:
        return {
          ...state,
          isFetching: false,
          data: []
        };

      case GET_SCHEDULE.REQUEST:
        return {
          ...state,
          isFetching: true
        };
  
      case GET_SCHEDULE.SUCCESS:
        return {
          ...state,
          isFetching: false,
          currentSchedule: action.result
        };
  
      case GET_SCHEDULE.FAIL:
        return {
          ...state,
          isFetching: false,
          currentSchedule: {}
        };
    
      case CANCEL_SCHEDULE.REQUEST:
        return {
          ...state,
          cancel: {
            isFetching: true,
            success: null,
            result: null
          }
        };
  
      case CANCEL_SCHEDULE.SUCCESS:
        return {
          ...state,
          cancel: {
            isFetching: false,
            success: true,
            result: action.result
          }
        };
  
      case CANCEL_SCHEDULE.FAIL:
        return {
          ...state,
          cancel: {
            isFetching: false,
            success: false,
            result: action.result
          }
        };

        case UPDATE_STATUS_SCHEDULE.REQUEST:
          return {
            ...state,
            updateStatus: {
              isFetching: true,
              success: null,
              result: null
            }
          };
    
        case UPDATE_STATUS_SCHEDULE.SUCCESS:
          return {
            ...state,
            updateStatus: {
              isFetching: false,
              success: true,
              result: action.result
            }
          };
    
        case UPDATE_STATUS_SCHEDULE.FAIL:
          return {
            ...state,
            updateStatus: {
              isFetching: false,
              success: false,
              result: action.result
            }
          };
  
      default:
        return state;
    }
  };