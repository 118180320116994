import { takeEvery, put } from 'redux-saga/effects';
import { FIND_USERS } from '../actions/user';
import API from '../../configs/API';
import { axiosGet } from './utils';
import queryString from 'query-string';

function* findUsers(obj) {
  const result = yield axiosGet(
    API.USER.FIND_BY_EMAIL_OR_PHONE +
      '?' +
      queryString.stringify({
        q: obj.q
      })
  );
  if (result.success) {
    yield put({
      type: FIND_USERS.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: FIND_USERS.FAIL,
      result
    });
  }
}

export function* watchUser() {
  yield takeEvery(FIND_USERS.REQUEST, findUsers);
}
