import { createRequestTypes } from './utils';

export const GET_COUPON = createRequestTypes('GET_COUPON');
export const FIND_COUPON = createRequestTypes('FIND_COUPON');
export const APPLY_COUPON = createRequestTypes('APPLY_COUPON');
export const FIND_COUPON_AND_APPLY = createRequestTypes('FIND_COUPON_AND_APPLY');
export const UPDATE_COUPON = createRequestTypes('UPDATE_COUPON');
export const CREATE_COUPON = createRequestTypes('CREATE_COUPON');
export const DELETE_COUPON = createRequestTypes('DELETE_COUPON');
export const GET_COUPON_DETAIL = createRequestTypes('GET_COUPON_DETAIL');

export const getCouponDetail = id => ({ type: GET_COUPON_DETAIL.REQUEST, id });
export const deleteCoupon = id => ({ type: DELETE_COUPON.REQUEST, id });
export const updateCoupon = (id, coupon) => ({ type: UPDATE_COUPON.REQUEST, id, coupon });
export const createCoupon = coupon => ({ type: CREATE_COUPON.REQUEST, coupon });
export const getCoupons = () => ({ type: GET_COUPON.REQUEST });
export const findCoupon = code => ({ type: FIND_COUPON.REQUEST, code });
export const findCouponAndApply = (code, schedule_id) => ({ type: FIND_COUPON_AND_APPLY.REQUEST, code, schedule_id });
export const applyCoupon = (coupon_id, schedule_id) => ({
  type: APPLY_COUPON.REQUEST,
  coupon_id,
  schedule_id
});
