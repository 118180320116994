import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import 'moment/locale/vi'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import ScheduleModal from './ScheduleModal';
// import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'
// import timeGridPlugin from '@fullcalendar/timegrid';

// import './main.scss'

import './calendar.css';

const messages = {
  allDay: 'Cả ngày',
  previous: 'Trước',
  next: 'Sau',
  today: 'Hôm nay',
  month: 'Tháng',
  week: 'Tuần',
  day: 'Ngày',
  agenda: 'Danh sách',
  date: 'Ngày',
  time: 'Thời gian',
  event: 'Sự kiện',
  showMore: total => `+ Xem thêm (${total})`
};

moment.locale("vi")
const localizer = momentLocalizer(moment)

export class ScheduleCalendar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      currentSchedule: null,
      showCancel: false,
      currentDate: moment()
    }

    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
  }

  convertToEvent(schedules) {
    let events = [];
    schedules.forEach(schedule => 
      {
        const time = moment(schedule.time, "DD/MM/YYYY HH:mm")
        const start = new Date(time.year(), time.month(), time.date(), time.hour() + 1, time.minute());
        
        const event = {
          id: schedule.id,
          title: time.format('HH:mm').toString() + ' - ' + schedule.user.name,
          user: schedule.user.name,
          start: start,
          end: start,
          status: schedule.status,
          isCanceled: schedule.isCanceled
        };
        // if(!this.state.showCancel && schedule.isCanceled) {
        //   return;
        // }
        events.push(event);
      }
    )
    return events;
  }

  onNavigate(date) {
    this.props.onSetMonth(date.getMonth() + 1, date.getFullYear());
    this.setState({
      currentDate: moment(date)
    })
  }
  
  onSelectEvent(info) {
    const event = info.event || info;
    const schedule = this.props.schedules.find(item => item.id === event.id);
    
    this.setState({
      currentSchedule: schedule
    }, () => {
      this.forceUpdate();
      
      window.$('#scheduleModal').modal('toggle')
    });
  }

  onChangeShowCancel = (ref) => {
    this.setState({ showCancel: ref.target.value == 'on'}, () => {
      this.forceUpdate();
    })
  }

  hourToInt(hour) {
    if (!hour) return 0;
    let _h = parseInt(hour.match(/^\d+/)[0]);
    if (/am/i.test(hour)) {
      if (_h === 12) {
        return 24;
      }
      return _h;
    }
    if (_h === 12) return 12;
    return _h + 12;
  }

  eventColor(event) {
    let color = '##343a40';
    if(event.isCanceled) {
      color = '#dc3545';
      return color;
    } 
    if(event.status === 'confirm') {
      color = '#007bff';
    }
    if(event.status === 'done') {
      color = '#28a745';
    }
    if(event.status === 'pending') {
      color = '#343a40';
    }
    return color;
  }

  render() {
    const { schedules, currentShop } = this.props;
    const { currentDate } = this.state;
  
    const AgendaEvent = ({ event }) => {
      let color = this.eventColor(event);
      return (
        <button className="btn btn-link" onClick={() => this.onSelectEvent(event)}>
          <span style={{ color: color }}>{event.title}</span>
        </button>
        );
    }

    const MonthEvent = ({ event }) => {
      // if(!this.state.showCancel && event.isCanceled)
      //   return null;
      let status = '';
      let color = this.eventColor(event);
      let textColor = '#FFF';
      
      return (
        <div style={{ backgroundColor: color, color: textColor, borderRadius: 10, padding: 3, paddingLeft: 10 }}>
          <span className={status}>{event.title}</span>
      </div>)};

    const DayEvent = ({ event }) => {
      let color = this.eventColor(event);

      return (
        <div className={"rbc-event-content "} style={{ backgroundColor: color, color: '#FFF', borderRadius: 10, padding: 3, paddingLeft: 10 }} title={event.title}>
           {event.user}
        </div>
    )};

    let events = this.convertToEvent(schedules);

    let min = new Date(currentDate.year(), currentDate.month(), currentDate.date(), 8, 0, 0);
    let max = new Date(currentDate.year(), currentDate.month(), currentDate.date(), 22, 0, 0);

    if(currentShop) {
      min = new Date(currentDate.year(), currentDate.month(), currentDate.date(), this.hourToInt(currentShop.from), 0, 0);
      max = new Date(currentDate.year(), currentDate.month(), currentDate.date(), this.hourToInt(currentShop.to), 0, 0);
    }

    return (
      <div>
        {/* <div className="col">
          <div className="form-group">
            <div className="input-group">
              <input type="checkbox" id="showCancel" onChange={this.onChangeShowCancel} className="mr-3" />
              <label for="showCancel">Hiển thị lịch hẹn đã hủy</label>
            </div>
          </div>
        </div> */}
        <ScheduleModal schedule={this.state.currentSchedule} />

        <Calendar
          components={{
            month: { event: MonthEvent },
            day: { event: MonthEvent },
            week: { event: MonthEvent },
            agenda: {
              event: AgendaEvent // with the agenda view use a different component to render events
            }
          }}
          views={['month', 'day', 'agenda']}
          min={min}
          max={max}
          
          // defaultView={'day'}
          culture={"vi-VN"}
          onSelectEvent={this.onSelectEvent}
          popup={true}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          onNavigate={this.onNavigate}
          messages={messages}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  schedules: state.schedule.data,
});
const mapDispatchToProps = {
  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleCalendar);