import { takeEvery, put } from 'redux-saga/effects';
import API from '../../configs/API';
import { axiosGet, axiosPost } from './utils';
import queryString from 'query-string';
import { GET_POSTS } from '../actions/post';

function* getPosts(obj) {
  const result = yield axiosGet(
    API.BLOG.GET +
      '?' +
      queryString.stringify(obj.params)
  );
  if (result.success) {
    yield put({
      type: GET_POSTS.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_POSTS.FAIL,
      result
    });
  }
}


export function* watchPost() {
  yield takeEvery(GET_POSTS.REQUEST, getPosts);
}
