import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addScheduleNotification, getScheduleNotifications } from '../../redux/actions/schedule_notification';
import moment from 'moment';


class NotificationModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      coupon: {}
    };

    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.create && this.props.create.success !== prevProps.create.success && prevProps.create.success == null) {
      if (this.props.create.success == true) {
        window.$('#NotificationModal').modal('toggle');
        this.props.getScheduleNotifications();
      }
      else {
        alert(this.props.create.result);
      }
    }
  }

  onCancel() {
    const { schedule } = this.props;
    this.props.cancelSchedule(schedule.id);
  }

  onSave() {
    const { title, body, push_at } = this.state;

    if(!title) {
      alert('Vui lòng điền tiêu đề');
      return;
    }

    if(!push_at) {
      alert('Vui lòng điền thời gian push');
      return;
    }
    
    this.props.addScheduleNotification(title, body, moment(push_at).utc())
  }

  render() {
    

    return (
      <div id="NotificationModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Tạo push thông báo</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Tiêu đề:</label>
                </div>
                <div className="col-md-8">
                  <input type="text" className="form-control" value={this.state.title || ''} onChange={ref => this.setState({ title: ref.target.value })} />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Nội dung:</label>
                </div>
                <div className="col-md-8">
                  <textarea className="form-control" value={this.state.body || ''} onChange={ref => this.setState({ body: ref.target.value })}/>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <label>Thời gian push:</label>
                </div>
                <div className="col-md-8">
                  <input type="datetime-local" className="form-control" value={this.state.push_at || ''} onChange={ref => this.setState({ push_at: ref.target.value })}  />
                </div>
              </div>
            </div>
            <div className="modal-footer">

              <button type="button" className="btn btn-primary" onClick={this.onSave}>Lưu</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  create: state.schedule_notification.create,
});
const mapDispatchToProps = {
  addScheduleNotification,
  getScheduleNotifications
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationModal);