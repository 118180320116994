import { takeEvery, put } from 'redux-saga/effects';
import { GET_SERVICE } from '../actions/service';
import API from '../../configs/API';
import { axiosGet } from './utils';
import queryString from 'query-string';

function* getService(obj) {
  const result = yield axiosGet(
    API.SERVICE.GET +
      '?' +
      queryString.stringify({
        shopId: obj.shopId,
        groupServiceId: obj.groupService
      })
  );
  if (result.success) {
    yield put({
      type: GET_SERVICE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_SERVICE.FAIL,
      result
    });
  }
}

export function* watchService() {
  yield takeEvery(GET_SERVICE.REQUEST, getService);
}
