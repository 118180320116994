import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dropzone from '../../components/Gallery/Dropzone';
import GalleryModal from '../../components/Gallery/GalleryModal';

import { Redirect } from 'react-router-dom';

import { galleryDetail, deleteGallery } from '../../redux/actions/gallery';
import { uploadPhoto, deletePhoto } from '../../redux/actions/photo';


export class Coupon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
    };
    this.uploadPhoto = this.uploadPhoto.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDeleteGallery = this.onDeleteGallery.bind(this);
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    
    if(params.id) {
      this.props.galleryDetail(params.id);
    }
  }

  uploadPhoto(images) {
    this.props.uploadPhoto(images);
  }

  onDelete(photo) {
    if(window.confirm(`Bạn có chắc chắn muốn xóa ảnh '${photo.name}' này?`)) {
      this.props.deletePhoto(photo.id)
    }
  }

  onEdit() {
    window.$('#galleryModal').modal('toggle')
  }

  onDeleteGallery() {
    if(window.confirm("Bạn có chắc chắn muốn xóa bộ sưu tập này?")) {
      this.props.deleteGallery(this.props.gallery.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.isDeleting !== prevProps.isDeleting && this.props.isDeleting == false) {
      if(this.props.deleteResult.success) {
        this.setState({
          redirect: true
        })
      }
      else {
        alert("Có lỗi xảy ra, không thể xóa");
      }
    }
  } 

  render() {
    const { photos, gallery, user } = this.props;
    const { match: { params } } = this.props;
    if(!user || !user.isAdmin) {
      return <Redirect to="/" />
    }

    if(!gallery) {
      return <div/>
    }

    if(this.state.redirect) {
      return <Redirect to="/gallery" />
    }
    
    return <div className="container" style={{ marginTop: '80px' }}>
      <GalleryModal gallery={gallery} />
      <div className="row mb-3">
        <div className="col-md-12">
          <h3>{gallery.name}</h3>
          <button type="button" className="btn btn-link " onClick={this.onEdit}>Chỉnh sửa bộ sưu tập</button>
          {gallery.id 
          &&
          <button type="button" className="btn btn-link text-danger float-right" onClick={this.onDeleteGallery}>Xóa</button>
          }
          
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-12">
          <Dropzone uploadPhoto={this.uploadPhoto} galleryId={params.id} />
          {this.props.isUploading &&
          <h4 className="text-center mt-3">Đang upload ảnh ....</h4>
          }
        </div>
      </div>
      <div class="row">
        {photos && photos.map(photo => 
        <div class="col-md-3 mb-5">
          <div class="card card-collection">
            <a
            href={photo.image}
            data-fancybox="gallery" data-caption={photo.name}>
              <img class="card-img-top w-100"
                style={{ height: 200 }}
                src={photo.image}
                alt={photo.name} />
            </a>
            <div className="card-footer">
              <button className="btn btn-link text-danger float-right" onClick={() => this.onDelete(photo)}>Xóa</button>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  photos: state.gallery.photos,
  gallery: state.gallery.gallery,
  isUploading: state.photo.isUploading,
  photo: state.photo.photo,
  isDeleting: state.gallery.isDeleting,
  deleteResult: state.gallery.deleteResult
});
const mapDispatchToProps = {
  galleryDetail,
  uploadPhoto,
  deletePhoto,
  deleteGallery
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupon);