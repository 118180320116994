import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCoupons, findCouponAndApply, applyCoupon } from '../../redux/actions/coupon';
import moment from 'moment';
import numeral from 'numeral';

class ScheduleCouponModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      coupon: null
    };

    this.applyCoupon = this.applyCoupon.bind(this);
    this.onSearchUser = this.onSearchUser.bind(this);
    this.closeAndRedirect = this.closeAndRedirect.bind(this);
  }

  onSearchUser(ref) {
    this.props.findUsers(this.state.q);
  }

  componentDidMount() {
    this.props.getCoupons();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.isFinding !== prevProps.isFinding && prevProps.isFinding == true) {
      if(!this.props.findSuccess) {
        alert('Coupon đã được sử dụng hoặc không tồn tại');
      }
    }
    if(this.props.isApplying !== prevProps.isApplying && prevProps.isApplying === true) {
      if(this.props.applySuccess) {
        alert('Cật nhật mã giảm giá thành công!');
        this.closeAndRedirect();
      }
      else {
        alert('Coupon đã được sử dụng hoặc không tồn tại');
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    
    if(!state.schedule || state.schedule.id !== props.schedule.id) {
      return {
        schedule: props.schedule
      }
    }
    return null;
  }

  closeAndRedirect() {
    window.$('#ScheduleCouponModal').modal('toggle')
    this.props.onSetRedirect();
  }
  
  applyCoupon(coupon, type) {
    if(!this.state.schedule)
    {
      alert("Không thể áp dụng coupon khi không có lịch hẹn");
      return;
    }

    const scheduleId = this.state.schedule.id || this.state.schedule._id;

    if(type == 'code') {
      this.props.findCouponAndApply(coupon, scheduleId);
    }
    else {
      this.props.applyCoupon(coupon, scheduleId);
    }
    
  }

  render() {
    const { coupons } = this.props;
    
    return (
    <div id="ScheduleCouponModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">Khách hàng</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

            <div className="row">
              <div className="col-md-12">
                <div className="input-group">
                  <input type="text" onChange={(ref) => this.setState({ coupon: ref.target.value })} className="form-control" placeholder="Nhập coupon" />
                  <button className="btn btn-primary ml-3" onClick={() => this.applyCoupon(this.state.coupon, 'code')}>Áp dụng</button>
                </div>
              </div>
            </div>
            <table className="table table-bordered mt-3">
              <thead>
                <tr>
                  <th>Tên</th>
                  <th>Code</th>
                  <th>Mô tả</th>
                  <th>Trị giá</th>
                  <th>HSD</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {coupons && coupons.map(coupon => 
                  <tr key={coupon.id}>
                    <td>{coupon.name}</td>
                    <td>{coupon.code}</td>
                    <td>{coupon.description}</td>
                    <td>{coupon.discount_type === 'percent' ? coupon.discount_value + '%' : numeral(coupon.discount_value).format('0,0') + ' VND'}</td>
                    <td>{moment(coupon.end_at).format('DD-MM-YYYY')}</td>
                    <td>
                      <button className="btn btn-outline-primary" onClick={() => this.applyCoupon(coupon.id, 'id')}>Áp dụng</button>
                    </td>
                  </tr>
                )
                }
                
              </tbody>
            </table>
            
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={this.closeAndRedirect}>Bỏ qua</button>
          </div>
        </div>
      </div>
    </div>
    )
  }
}


const mapStateToProps = state => ({
  findResults: state.user.findResults,
  coupons: state.coupon.coupons,
  isFinding: state.coupon.isFinding,
  findSuccess: state.coupon.findSuccess,
  isApplying: state.coupon.isApplying,
  applySuccess: state.coupon.applySuccess
});
const mapDispatchToProps = {
  getCoupons,
  findCouponAndApply,
  applyCoupon
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleCouponModal);