import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_SCHEDULE_NOTIFICATIONS,
  ADD_SCHEDULE_NOTIFICATION,
  DELETE_SCHEDULE_NOTIFICATION
} from '../actions/schedule_notification';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosDelete } from './utils';
import queryString from 'query-string';

function* getNotifications(obj) {
  const result = yield axiosGet(
    API.SCHEDULE_NOTIFICATION.GET +
      '?page=' + obj.page
  );
  if (result.success) {
    yield put({
      type: GET_SCHEDULE_NOTIFICATIONS.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_SCHEDULE_NOTIFICATIONS.FAIL,
      result
    });
  }
}

function* createNotification(obj) {
  const result = yield axiosPost(
    API.SCHEDULE_NOTIFICATION.CREATE,
    {
      title: obj.title,
      body: obj.body,
      push_at: obj.push_at
    }
  );
  if (result.success) {
    yield put({
      type: ADD_SCHEDULE_NOTIFICATION.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: ADD_SCHEDULE_NOTIFICATION.FAIL,
      result
    });
  }
}

function* deleteNotification(obj) {
  const result = yield axiosDelete(
    API.SCHEDULE_NOTIFICATION.DELETE.replace('{id}', obj.id)
  );
  if (result.success) {
    yield put({
      type: DELETE_SCHEDULE_NOTIFICATION.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: DELETE_SCHEDULE_NOTIFICATION.FAIL,
      result
    });
  }
}

export function* watchScheduleNotification() {
  yield takeEvery(GET_SCHEDULE_NOTIFICATIONS.REQUEST, getNotifications);
  yield takeEvery(ADD_SCHEDULE_NOTIFICATION.REQUEST, createNotification);
  yield takeEvery(DELETE_SCHEDULE_NOTIFICATION.REQUEST, deleteNotification);
}
