import { takeEvery, put } from 'redux-saga/effects';
import API from '../../configs/API';
import { axiosGet, axiosPost } from './utils';
import queryString from 'query-string';
import { GET_PROVINCES } from '../actions/province';

function* getProvinces(obj) {
  const result = yield axiosGet(
    API.PROVINCE.GET +
      '?' +
      queryString.stringify(obj.params)
  );
  if (result.success) {
    yield put({
      type: GET_PROVINCES.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_PROVINCES.FAIL,
      result
    });
  }
}


export function* watchProvince() {
  yield takeEvery(GET_PROVINCES.REQUEST, getProvinces);
}
