import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import sagas from './redux/sagas';
import reducers from './redux/reducers';
import axios from 'axios';
import Home from './containers/Home';
import Drawer from './containers/Drawer';
import Schedule from './containers/Schedule/index';
import AddSchedule from './containers/Schedule/AddSchedule';
import Login from './containers/Auth/Login';
import Coupon from './containers/Coupon/index';
import CouponUser from './containers/Coupon/CouponUser';
import Conversation from './containers/Conversation/index';
import Gallery from './containers/Gallery/index';
import GalleryDetail from './containers/Gallery/Detail';
import ScheduleNotification from './containers/ScheduleNotification';
import Shop from './containers/Shop';
import AddShop from './containers/Shop/Add';
import EditShop from './containers/Shop/Edit';
import Post from './containers/Post';
import AddPost from './containers/Post/Add';
import EditPost from './containers/Post/Edit';
import { Redirect } from 'react-router-dom';

const defaultFontFamily = [
  'Nunito Sans',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',')


let rehydrationComplete;
const rehydrationPromise = new Promise((resolve, reject) => {
  rehydrationComplete = resolve;
});
const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleware()
const store = compose(applyMiddleware(sagaMiddleware))(
  createStore
)(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
);


sagaMiddleware.run(sagas)


class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rehydrate: false
    }

    this.persistor = persistStore(store, {}, () => {
      rehydrationComplete();
      const { token } = store.getState().login;
      axios.defaults.headers.common.Authorization = 'Bearer ' + token;
      this.setState({ rehydrated: true });
    });
  }

  render() {
    if(!this.state.rehydrated){
      return <div>Loading</div>;
    }
    return (
      <PersistGate loading={null} persistor={this.persistor}>
        <Router>
          <Provider store={store}>
            <Switch>
              <Route exact path="/login" component={Login} />

              <Drawer>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/schedule" component={Schedule} />
                  <Route exact path="/schedule/:id/edit" component={AddSchedule} />
                  <Route exact path="/schedule/add" component={AddSchedule} />
                  <Route exact path="/coupon" component={Coupon} />
                  <Route exact path="/shop/add" component={AddShop} />
                  <Route exact path="/shop/:id/edit" component={EditShop} />
                  <Route exact path="/shop" component={Shop} />
                  <Route exact path="/posts" component={Post} />
                  <Route exact path="/post/add" component={AddPost} />
                  <Route exact path="/post/:id/edit" component={EditPost} />
                  <Route exact path="/coupon/:id/users" component={CouponUser} />
                  <Route exact path="/conversation" component={Conversation} />
                  <Route exact path="/gallery" component={Gallery} />
                  <Route exact path="/gallery/:id" component={GalleryDetail} />
                  <Route exact path="/schedule_notifications" component={ScheduleNotification} />
                </Switch>
              </Drawer>
            </Switch>
          </Provider>
        </Router>
      </PersistGate>
    );
  }
}

export default App;
