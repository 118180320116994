import { GET_GROUP_SERVICE } from '../actions/data';

const initialState = {
  isFetching: false,
  groupServices: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_SERVICE.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case GET_GROUP_SERVICE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        groupServices: action.result.group
      };

    case GET_GROUP_SERVICE.FAIL: {
      return {
        ...state,
        isFetching: false
      };
    }

    default:
      return state;
  }
};
