export const API_ROOT = "https://lamia.vn/v1/";
// export const API_ROOT = "http://localhost:4020/v1/";

export default {
  AUTH: {
    GOOGLE: API_ROOT + "auth/google",
    FACEBOOK: API_ROOT + "auth/facebook",
    LOGIN: API_ROOT + "auth/signin",
  },
  BLOG: {
    GET: API_ROOT + "posts",
    CATEGORIES: API_ROOT + "categories",
    DETAIL: API_ROOT + "post/{id}",
    COMMENT: API_ROOT + "post/{id}/comments",
    POST_COMMENT: API_ROOT + "post/{id}/comment",
  },
  SCHEDULE: {
    LIST: API_ROOT + "schedule",
    GET: API_ROOT + "schedule/{id}",
    CANCEL: API_ROOT + "schedule-cancel/{id}",
    STATUS: API_ROOT + "schedule/{id}/status",
  },
  DATA: {
    GROUP_SERVICE: API_ROOT + "group-services",
  },
  ME: {
    GET: API_ROOT + "me",
    UPDATE: API_ROOT + "me/update",
    UPDATE_AVATAR: API_ROOT + "me/avatar",
  },
  GALLERY: {
    CREATE: API_ROOT + "galleries",
    GET: API_ROOT + "galleries",
    DETAIL: API_ROOT + "gallery/{id}",
    LIKE_GALLERY: API_ROOT + "like-gallery/{id}",
    LIKE_PHOTO: API_ROOT + "like-photo/{id}",
    COMMENT: API_ROOT + "gallery/{id}/comments",
  },
  PHOTO: {
    UPLOAD_PHOTO: API_ROOT + "photos",
    DELETE_PHOTO: API_ROOT + "photo/{id}",
  },
  UPDATE_DEVICE_INFO: API_ROOT + "device",
  SHOP: {
    GET: API_ROOT + "shops",
    DETAIL: API_ROOT + "shops/:id",
    UPDATE: API_ROOT + "shops/:id",
    DAY_OF_WEEK: API_ROOT + "day-of-week",
  },
  PROVINCE: {
    GET: API_ROOT + "provinces",
  },
  SCHEDULE_NOTIFICATION: {
    GET: API_ROOT + "notifications",
    CREATE: API_ROOT + "notifications",
    DELETE: API_ROOT + "notifications/{id}",
  },
  LOCATION: {
    GET: API_ROOT + "locations",
    PROVINCE: API_ROOT + "provinces",
    DISTRICT: API_ROOT + "districts/{id}",
  },
  SERVICE: {
    GET: API_ROOT + "services",
    GROUP: API_ROOT + "group-services",
  },
  COUPON: {
    GET: API_ROOT + "coupons",
    CREATE: API_ROOT + "coupons",
    DETAIL: API_ROOT + "coupon/{id}",
    UPDATE: API_ROOT + "coupon/{id}",
    DELETE: API_ROOT + "coupon/{id}",
    FIND: API_ROOT + "coupons/find",
    APPLY: API_ROOT + "coupon-apply",
  },
  USER: {
    FIND_BY_EMAIL_OR_PHONE: API_ROOT + "users/findByEmailOrPhone",
  },
  BOOKING: {
    AVAILABILITY: API_ROOT + "schedule-check",
    BOOK: API_ROOT + "schedule",
    UPDATE_BOOK: API_ROOT + "schedule/{id}",
  },
  CONVERSATION: {
    GET: API_ROOT + "conversations",
    SEND_MESSAGE: API_ROOT + "message/send",
    RECEIVED: API_ROOT + "message/received",
    SEND_IMAGE: API_ROOT + "message/upload",
  },
};
