import {
  UPLOAD_PHOTO,
  DELETE_PHOTO
} from '../actions/photo';

const initialState = {
  isUploading: false,
  photo: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_PHOTO.REQUEST:
      return {
        ...state,
        isUploading: true
      };

    case UPLOAD_PHOTO.SUCCESS:
      return {
        ...state,
        isUploading: false,
        ...action.result
      };

    case UPLOAD_PHOTO.FAIL: {
      return {
        ...state,
        isUploading: false
      };
    }

    case DELETE_PHOTO.REQUEST:
      return {
        ...state,
        isDeleting: true
      };

    case DELETE_PHOTO.SUCCESS:
      return {
        ...state,
        isDeleting: false,
        ...action.result
      };

    case DELETE_PHOTO.FAIL: {
      return {
        ...state,
        isDeleting: false
      };
    }

    default:
      return state;
  }
};
