import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import {  } from '../../redux/actions/post'
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function uploadImageCallBack(file) {
  return new Promise(
    (resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID XXXXX');
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    }
  );
}

export default (props) => {
  const dispatch = useDispatch();
  const [post, setPost] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if(props.post) {
      setPost(props.post)
      
    }
    else {
      
    }
  }, [props.post])

  const onChange = (editorState) => {
    setEditorState(editorState)
  }

  const onUpdate = () => {
   
  }

  return (
    <table className="table">
      <tbody>
        <tr>
          <th>Hình ảnh</th>
          <td>
            <div className="row">
              <div className="col-md-6">
                <input type="file" />
              </div>
              <div className="col-md-6">
                <img src={post.image} style={{ width: 150 }} />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>Tiêu đề</th>
          <th>
            <input type="text" className="form-control" placeholder="Tên cửa hàng" value={post.title || ''} onChange={ref => setPost({ ...post, title: ref.target.value })} />
          </th>
        </tr>
        <tr>
          <th>Nội dung rút gọn</th>
          <td>
            <textarea className="form-control" rows={5} placeholder="Nội dung rút gọn" value={post.brief || ''} onChange={ref => setPost({ ...post, brief: ref.target.value })}  />
          </td>
        </tr>
        <tr>
          <th>Nội dung</th>
          <td>
            {/* <textarea className="form-control" placeholder="Nội dung" value={post.content || ''} onChange={ref => setPost({ ...post, content: ref.target.value })}  /> */}
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onChange}
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
              }}
            />

          </td>
        </tr>
        
        <tr>
          <td></td>
          <td>
            <button className="btn btn-primary" onClick={onUpdate}>Cập nhật</button>
          </td>
        </tr>
      </tbody>
    </table>
  )
}