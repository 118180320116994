import { takeEvery, put } from 'redux-saga/effects';
import { GET_COUPON, FIND_COUPON, APPLY_COUPON, FIND_COUPON_AND_APPLY, UPDATE_COUPON, CREATE_COUPON, DELETE_COUPON, GET_COUPON_DETAIL } from '../actions/coupon';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosPut, axiosDelete } from './utils';
import queryString from 'query-string';

function* getCoupons() {
  const result = yield axiosGet(API.COUPON.GET);
  if (result.success) {
    yield put({
      type: GET_COUPON.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_COUPON.FAIL,
      result
    });
  }
}

function* updateCoupon(obj) {
  const result = yield axiosPut(API.COUPON.UPDATE.replace('{id}', obj.id), {
    ...obj.coupon
  });
  if (result.success) {
    yield put({
      type: UPDATE_COUPON.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_COUPON.REQUEST
    });
  } else {
    yield put({
      type: UPDATE_COUPON.FAIL,
      result
    });
  }
}

function* createCoupon(obj) {
  const result = yield axiosPost(API.COUPON.CREATE, {
    ...obj.coupon
  });
  if (result.success) {
    yield put({
      type: CREATE_COUPON.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_COUPON.REQUEST
    });
  } else {
    yield put({
      type: CREATE_COUPON.FAIL,
      result: result.errorMessage.response.data.message
    });
  }
}

function* findCoupon(obj) {
  const { code } = obj;
  const result = yield axiosGet(
    API.COUPON.FIND +
      '?' +
      queryString.stringify({
        code
      })
  );
  if (result.success) {
    yield put({
      type: FIND_COUPON.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: FIND_COUPON.FAIL,
      result
    });
  }
}

function* getCouponDetail(obj) {
  
  const result = yield axiosGet(
    API.COUPON.DETAIL.replace('{id}', obj.id)
  );
  if (result.success) {
    yield put({
      type: GET_COUPON_DETAIL.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_COUPON_DETAIL.FAIL,
      result
    });
  }
}


function* findCouponAndApply(obj) {
  const { code, schedule_id } = obj;
  const result = yield axiosGet(
    API.COUPON.FIND +
      '?' +
      queryString.stringify({
        code
      })
  );
  if (result.success) {
    yield put({
      type: FIND_COUPON.SUCCESS,
      result: result.data
    });
    yield put({
      type: APPLY_COUPON.REQUEST,
      coupon_id: result.data.coupon.id, 
      schedule_id
    });
  } else {
    yield put({
      type: FIND_COUPON.FAIL,
      result
    });
  }
}

function* applyCoupon(obj) {
  const { coupon_id, schedule_id } = obj;
  const result = yield axiosPost(API.COUPON.APPLY, {
    coupon_id,
    schedule_id
  });
  if (result.success) {
    yield put({
      type: APPLY_COUPON.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: APPLY_COUPON.FAIL,
      result
    });
  }
}

function* deleteCoupon(obj) {
  
  const result = yield axiosDelete(API.COUPON.DELETE.replace('{id}', obj.id));
  if (result.success) {
    yield put({
      type: DELETE_COUPON.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_COUPON.REQUEST
    });
  } else {
    yield put({
      type: DELETE_COUPON.FAIL,
      result
    });
  }
}

export function* watchCoupon() {
  yield takeEvery(GET_COUPON.REQUEST, getCoupons);
  yield takeEvery(FIND_COUPON.REQUEST, findCoupon);
  yield takeEvery(APPLY_COUPON.REQUEST, applyCoupon);
  yield takeEvery(FIND_COUPON_AND_APPLY.REQUEST, findCouponAndApply);

  yield takeEvery(UPDATE_COUPON.REQUEST, updateCoupon);
  yield takeEvery(CREATE_COUPON.REQUEST, createCoupon);
  yield takeEvery(DELETE_COUPON.REQUEST, deleteCoupon);

  yield takeEvery(GET_COUPON_DETAIL.REQUEST, getCouponDetail);
}
