import { createRequestTypes } from './utils';

export const UPLOAD_PHOTO = createRequestTypes('UPLOAD_PHOTO');
export const DELETE_PHOTO = createRequestTypes('DELETE_PHOTO');

export const uploadPhoto = (images) => ({
  type: UPLOAD_PHOTO.REQUEST,
  images
});

export const deletePhoto = id => ({
  type: DELETE_PHOTO.REQUEST,
  id
})
