import React, { Component } from "react";
import firebase from "../../redux/firebase";
import { connect } from "react-redux";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import {
  sendMessage,
  sendImage,
  receivedMessage,
} from "../../redux/actions/conversation";

class ConversationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      content: "",
    };

    this.onSend = this.onSend.bind(this);
  }

  componentDidMount() {
    this.listenFirebase();
  }

  listenFirebase() {
    const { conversation } = this.props;
    if (!conversation) return;
    // let db = firebase.firestore();
    const db = getFirestore(firebase);
    let doc = collection(db, "conversations/" + conversation.id + "/messages");
    const q = query(doc);
    this.observer = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          var message = change.doc.data();
          if (!message.received) {
            // console.log(message.messageId)
            // this.props.receivedMessage(message.messageId)
          }
          this.setState({
            messages: [...this.state.messages, message],
          });
          this.updateScroll();
        }

        // this.setState({conversations: change.doc.data()})
      });
    });
  }

  componentWillUnmount() {
    this.observer = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.conversation &&
      this.props.conversation &&
      prevProps.conversation.id !== this.props.conversation.id
    ) {
      this.listenFirebase();
    }
  }

  updateScroll() {
    var element = document.getElementById("msg_history");
    if (element != null) {
      element.scrollTop = element.scrollHeight;
    }
  }

  onSend() {
    const { conversation, user } = this.props;

    this.props.sendMessage(conversation.id, this.state.content);
    this.setState({
      content: "",
    });
  }

  onChangeImage = () => {
    const file = this.image.files[0];

    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        let image = new Image();
        image.src = reader.result;
        image.onload = () => {
          if (file.size / 1024 / 1024 > 5) {
            alert("Max size of image is 5MB");
          } else {
            const { id } = this.props.conversation;

            this.props.sendImage("test.png", file, id);

            this.image.value = "";
          }
        };
      },
      false
    );

    if (typeof file !== "undefined") {
      reader.readAsDataURL(file);
    }
  };

  onSelectImage = () => {
    if (this.image) {
      this.image.click();
    }
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.onSend();
    }
  };

  render() {
    const { conversation, user } = this.props;

    if (!conversation) {
      return <div />;
    }

    return (
      <div>
        <div
          className="msg_history"
          id="msg_history"
          style={{ flexGrow: 1, height: "75vh", overflowY: "scroll" }}
        >
          {this.state.messages.map(
            (message) =>
              (message.senderId !== user.id && (
                <div className="incoming_msg mb-3" key={message.messageId}>
                  <div className="incoming_msg_img">
                    {" "}
                    <img
                      src="https://ptetutorials.com/images/user-profile.png"
                      alt="sunil"
                    />{" "}
                  </div>
                  <div className="received_msg">
                    <div className="received_withd_msg">
                      <span className="time_date">{message.senderName}</span>
                      <p>
                        {(message.image && (
                          <a href={message.image} target="_blank">
                            <img
                              src={message.image}
                              style={{ maxWidth: "150px", maxHeight: "150px" }}
                            />
                          </a>
                        )) ||
                          message.content}
                      </p>
                    </div>
                  </div>
                </div>
              )) || (
                <div className="outgoing_msg" key={message.messageId}>
                  <div className="sent_msg">
                    <span className="time_date">{message.senderName}</span>
                    <p>
                      {(message.image && (
                        <a href={message.image} target="_blank">
                          <img
                            src={message.image}
                            style={{ maxWidth: "150px", maxHeight: "150px" }}
                          />
                        </a>
                      )) ||
                        message.content}
                    </p>
                  </div>
                </div>
              )
          )}
        </div>
        <div className="type_msg">
          <div className="input_msg_write">
            <button
              className="msg_send_img"
              type="button"
              onClick={this.onSelectImage}
            >
              <i className="fa fa-image" aria-hidden="true"></i>
            </button>

            <input
              type="text"
              className="write_msg"
              style={{ marginLeft: "40px" }}
              placeholder="Type a message"
              onKeyDown={this._handleKeyDown}
              value={this.state.content}
              onChange={(ref) => this.setState({ content: ref.target.value })}
            />
            <input
              type="file"
              ref={(ref) => (this.image = ref)}
              accept="image/*"
              onChange={this.onChangeImage}
              style={{ display: "none" }}
            />

            <button
              className="msg_send_btn"
              type="button"
              onClick={this.onSend}
            >
              <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.login.user,
});
const mapDispatchToProps = {
  sendMessage,
  sendImage,
  receivedMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationDetail);
