import { takeEvery, put } from 'redux-saga/effects';
import { GET_SHOP, FAVORITE_SHOP, GET_SHOP_DETAIL, GET_DAY_OF_WEEK, UPDATE_SHOP, CREATE_SHOP } from '../actions/shop';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosPut } from './utils';
import queryString from 'query-string';

function* getShops(obj) {
  const result = yield axiosGet(
    API.SHOP.GET +
      '?' +
      queryString.stringify({
        province: obj.provinceId,
        district: obj.districtId
      })
  );
  if (result.success) {
    yield put({
      type: GET_SHOP.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_SHOP.FAIL,
      result
    });
  }
}

function* getShopDetail(obj) {
  const result = yield axiosGet(
    API.SHOP.DETAIL.replace(':id', obj.id)
  );
  if (result.success) {
    yield put({
      type: GET_SHOP_DETAIL.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_SHOP_DETAIL.FAIL,
      result
    });
  }
}

function* getDayOfWeek(obj) {
  const result = yield axiosGet(
    API.SHOP.DAY_OF_WEEK
  );
  if (result.success) {
    yield put({
      type: GET_DAY_OF_WEEK.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_DAY_OF_WEEK.FAIL,
      result
    });
  }
}

function* favoriteShop(obj) {
  const result = yield axiosPost(API.SHOP.FAVORITE, {
    shop: obj.shopId
  });
  if (result.success) {
    yield put({
      type: FAVORITE_SHOP.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: FAVORITE_SHOP.FAIL,
      result
    });
  }
}

function* updateShop(obj) {
  const result = yield axiosPut(API.SHOP.UPDATE.replace(':id', obj.id), obj.params);
  if (result.success) {
    yield put({
      type: UPDATE_SHOP.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: UPDATE_SHOP.FAIL,
      result: result.errorMessage.response.data
    });
  }
}


function* createShop(obj) {
  const result = yield axiosPost(API.SHOP.GET, obj.params);
  if (result.success) {
    yield put({
      type: CREATE_SHOP.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: CREATE_SHOP.FAIL,
      result: result.errorMessage.response.data
    });
  }
}

export function* watchShop() {
  yield takeEvery(GET_SHOP.REQUEST, getShops);
  yield takeEvery(UPDATE_SHOP.REQUEST, updateShop);
  yield takeEvery(CREATE_SHOP.REQUEST, createShop);
  yield takeEvery(GET_SHOP_DETAIL.REQUEST, getShopDetail);
  yield takeEvery(GET_DAY_OF_WEEK.REQUEST, getDayOfWeek);
  yield takeEvery(FAVORITE_SHOP.REQUEST, favoriteShop);
}
