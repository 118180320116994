import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link, Redirect } from 'react-router-dom';

import { getGalleries } from '../../redux/actions/gallery';
import GalleryModal from '../../components/Gallery/GalleryModal';


export class Coupon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
    };
  }

  componentDidMount() {
    this.props.getGalleries();
  }

  onAdd() {
    window.$('#galleryModal').modal('toggle')
  }

  render() {
    const { galleries, user } = this.props;

    if(!user || !user.isAdmin) {
      return <Redirect to="/" />
    }
    return <div className="container" style={{ marginTop: '80px' }}>
      <GalleryModal />
      <div className="row mb-3">
        <div className="col-md-12">
          <h3>Bộ sưu tập</h3>
          <button type="button" className="btn btn-link float-right" onClick={this.onAdd}>+ Tạo bộ sưu tập</button>
        </div>
      </div>
      <div className="row">
        {galleries && galleries.map(gallery => 
        <div className="col-md-3 mb-5">
          <Link className="card card-collection" to={"/gallery/" + gallery.id}>
              <img className="card-img-top w-100"
              src={gallery.image}
              alt={gallery.name} />
            <div className="card-body"><strong className="card-title text-center">{gallery.name}</strong></div>
          </Link>
        </div>
        )}
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  galleries: state.gallery.galleries,
  user: state.login.user
});
const mapDispatchToProps = {
  getGalleries
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupon);