import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

export default function MyDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    let images = [];
    acceptedFiles.forEach(file => {
      images.push({
        image: file,
        name: file.name,
        galleryId: props.galleryId
      });
    })

    props.uploadPhoto(images);
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({ 
    onDrop,
    acceptedFiles: "image/*"
  })

  return (
    <div {...getRootProps()} style={{
      border: '1px solid #6ca6a7',
      borderRadius: 10,
      padding: 30,
      height: 200
    }}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Kéo và thả file vào đây để upload</p>
      }
    </div>
  )
}