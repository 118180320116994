import { all, fork } from 'redux-saga/effects';
import { watchLogin } from './login';
import { watchBooking } from './booking';
import { watchSchedule } from './schedule';
import { watchService } from './service';
import { watchData } from './data';
import { watchShop } from './shop';
import { watchUser } from './user';
import { watchCoupon } from './coupon';
import { watchConversation } from './conversation';
import { watchGallery } from './gallery';
import { watchPhoto } from './photo';
import { watchProvince } from './province';
import { watchPost } from './post';
import { watchScheduleNotification } from './schedule_notification';

export default function* sagas() {
  yield all([
    fork(watchLogin),
    fork(watchBooking),
    fork(watchSchedule),
    fork(watchService),
    fork(watchData),
    fork(watchShop),
    fork(watchUser),
    fork(watchCoupon),
    fork(watchConversation),
    fork(watchGallery),
    fork(watchPhoto),
    fork(watchProvince),
    fork(watchPost),
    fork(watchScheduleNotification)
  ]);
}
