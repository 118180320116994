import { GET_POSTS } from '../actions/post';

const initialState = {
  isFetching: false,
  post: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POSTS.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case GET_POSTS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.result
      };

    case GET_POSTS.FAIL: {
      return {
        ...state,
        isFetching: false
      };
    }

    default:
      return state;
  }
};
