import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

import { getSchedules } from '../../redux/actions/schedule';
import { getGroupServices } from '../../redux/actions/service';
import { getShops } from '../../redux/actions/shop';
import ScheduleCalendar from '../../components/Schedule/ScheduleCalendar.js';

class Schedule extends Component {

  constructor(props) {
    super(props);

    this.state = {
      group: 'all',
      shop: 'all',
      currentMonth: (new Date()).getMonth() + 1,
      currentYear: (new Date()).getFullYear()
    }
    
    this.onFilter = this.onFilter.bind(this);
    this.onSetMonth = this.onSetMonth.bind(this);
    this.onChangeShop =  this.onChangeShop.bind(this);
    this.onChangeGroup = this.onChangeGroup.bind(this);
  }

  componentDidMount() {
    this.props.getSchedules(this.state.currentMonth, this.state.currentYear);
    this.props.getGroupServices();
    this.props.getShops();
  }

  componentDidUpdate(prevProps, prevState) {
    // if(this.state.shop == 'all' && prevProps.shops.length > 0) {
    //   this.setState({
    //     shop: this.props.shops[0].id
    //   })
    // }
    if(this.props.cancel && prevProps.cancel &&
       this.props.cancel.success !== prevProps.cancel.success && prevProps.cancel.success == null) {
      if(this.props.cancel.success == true) {
        this.onFilter();
      }
      
    }
    if(this.props.updateStatus && prevProps.updateStatus &&
       this.props.updateStatus.success !== prevProps.updateStatus.success && prevProps.updateStatus.success == null) {
      if(this.props.updateStatus.success == true) {
        this.onFilter();
      }
     
    }
  }
 
  onFilter() {
    const groupServiceId = this.state.group !== 'all' ? this.state.group : undefined;
    const shopId = this.state.shop !== 'all' ? this.state.shop : undefined;
    this.props.getSchedules(this.state.currentMonth, this.state.currentYear, groupServiceId, shopId);
  }

  onSetMonth(month, year) {
    if(month !== this.state.currentMonth) {
      this.setState({
        currentMonth: month,
        currentYear: year
      }, () => {
        this.onFilter();
      })
    }
  }

  onChangeGroup(ref) {
    this.setState({ group: ref.target.value }, () => {
      this.onFilter();
    })
  }

  onChangeShop(ref) {
    this.setState({ shop: ref.target.value }, () => {
      this.onFilter();
    })
  }

  render() {
    const { schedules, groupServices, shops, user } = this.props;
    const currentShop = this.props.shops && this.props.shops.find(x => x.id == this.state.shop);

    return <div style={{ flexGrow: 1 }} className="container-fluid" style={{ marginTop: '30px' }}>
          
      <div className="row">
        <div className="col">
          <Link to='/schedule/add' className="btn btn-link float-right" onClick={this.onAddBooking}>+ Tạo lịch hẹn</Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>Nhóm dịch vụ: </label>
            <select className="form-control" onChange={this.onChangeGroup}>
              <option value="all">Tất cả nhóm dịch vụ</option>
              {groupServices && groupServices.map(group =>
                <option key={group.id} value={group.id}>{group.name}</option>  
              )}
            </select>
          </div>
        </div>
        {user && (user.isAdmin || user.isSupport) && 
        <div className="col-md-4">
          <div className="form-group">
            <label>Cửa hàng: </label>
            <select className="form-control" onChange={this.onChangeShop}>
              <option value="all">Tất cả cửa hàng</option>
              {shops && shops.map(shop =>
                <option key={shop.id} value={shop.id}>{shop.title}</option>  
              )}
            </select>
          </div>
        </div>}
      </div>
      
      <div className="row">
        <div className="col-md-12">
          
          <ScheduleCalendar onSetMonth={this.onSetMonth} currentShop={currentShop} />
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  userId: state.login.userId,
  schedules: state.schedule.data,
  groupServices: state.data.groupServices,
  shops: state.shop.shops,
  cancel: state.schedule.cancel,
  updateStatus: state.schedule.updateStatus
});
const mapDispatchToProps = {
  getSchedules,
  getGroupServices,
  getShops
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Schedule);